import { T_LeafBool, T_Branch, T_Major } from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";

export const _contentRequest = {
    id: "contentRequest",
    permitType: PT_Org,
    type: T_Major,
    modules: [ ],
  };

export const _canCreateContentAlert = {
    id: "canCreateContentAlert",
    permitType: PT_Org,
    type: T_Major,
    modules: [ ],
  };