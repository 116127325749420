import Axios from "axios";
import store from "redux/Store";
import { FilterOperatorTypes } from "./table-view/TableTypes";
import { PlaylistStatusTypes } from "../modules/ml-playlist/mylist/Columns";
import { logoutUser } from "../redux/actions/UserActions";

var users = null;
var groups = null;
var playlistCategories = null;

export const keepAlive = () => {
  Axios.get(`/keepalive`)
    .then((res) => {
      if (!res?.data?.alive) {
        logoutUser();
      }
    })
    .catch((err) => {
      logoutUser();
    });
};

export const getPlaylistCategories = (playlistType) =>
  new Promise(function (resolve, reject) {
    if (playlistCategories !== null) {
      resolve(playlistCategories);
    } else {
      const {
        user: {
          credentials: { orgID },
        },
      } = store.getState();

      Axios.post(`${orgID}/plcategory/search`, {
        order: "asc",
        orderBy: [{ orderBy: "priority", order: "asc"},{ orderBy: "name", order: "asc"}],
        page: 0,
        size: 10000,
        filters: [
          {
            operator: FilterOperatorTypes.equals,
            value: [PlaylistStatusTypes.active],
            field: "status",
          },
          {
            operator: FilterOperatorTypes.equals,
            value: [playlistType],
            field: "playlistType",
          },
        ],
      })
        .then((res) => {
          playlistCategories = res?.data.results ? res.data.results : [];
          resolve(playlistCategories);
        })
        .catch((err) => {
          resolve([]);
        });
    }
  });

export const getDownloadLinks = (param) =>
  new Promise(function (resolve, reject) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    Axios.post(`${orgID}/downloadeableassets`, param)
      .then((res) => {
        resolve(res?.data?.links ? res.data.links : []);
      })
      .catch((err) => {
        resolve([]);
      });
  });

export const getMyPlaylists = (param) =>
  new Promise(function (resolve, reject) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    //resolve(roles.filter((v) => v.permitType == PT_Org));
    //resolve
    Axios.post(`${orgID}/playlist/editable`, {
      order: "desc",
      orderBy: "updatedAt",
      page: 0,
      size: 10000,
    })
      .then((res) => {
        resolve(res?.data?.results ? res.data.results : []);
      })
      .catch((err) => {
        resolve([]);
      });
  });

// export const getOrgUsers = (param) =>
//   new Promise(function (resolve, reject) {
//     if (users !== null) {
//       resolve(users);
//     } else {
//       const {
//         user: {
//           credentials: { orgID },
//         },
//       } = store.getState();

//       Axios.post(`${orgID}/getUsers`, {
//         order: "asc",
//         orderBy: "updatedAt",
//         page: 0,
//         size: 10000,
//       })
//         .then((res) => {
//           users = res?.data?.results ? res.data.results : [];
//           resolve(users);
//         })
//         .catch((err) => {
//           resolve([]);
//         });
//     }
//   });

export const getOrgGroups = (param) =>
  new Promise(function (resolve, reject) {
    if (groups !== null) {
      resolve(groups);
    } else {
      const {
        user: {
          credentials: { orgID },
        },
      } = store.getState();

      Axios.post(`${orgID}/getGroups`, {
        order: "asc",
        orderBy: "updatedAt",
        page: 0,
        size: 10000,
      })
        .then((res) => {
          groups = res?.data?.results ? res.data.results : [];
          resolve(groups);
        })
        .catch((err) => {
          resolve([]);
        });
    }
  });
