import { actionTypes } from "../actions/MLTrackUpdateAction";
import { SortTypes } from "../../util/table-view/TableTypes";
import { defaultResultsPerPage, pagingTotalRelation,  } from "../Types";


const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  trackSessionId: null,
  sessionItems: {},
  selectedItem: {},
  selectedItems: [],
  selectedAlbumTracks: [],
  selectionType: null,
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  trackEditing: null,
  refresh: false,
  noMore: true,
  playlist: null,
  currentSessionId: null,
  percentage: 0,
  albumData: null,
  refresh: true,
  showAddTracks: false,
  albumEditing: null,
  query: {
    order: SortTypes.desc,
    orderBy: "date_last_edited",
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    filters: []
  },
  errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.REFRESH:
      return {
        ...state,
        refresh: action.status
      };

    case actionTypes.ADD_TRACKS_SHOW:
      return {
        ...state,
        showAddTracks: action.status
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
        selectedItem: null,
      };

    case actionTypes.ALBUM_EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };


    case actionTypes.TRACK_EDIT_ITEM:
      return {
        ...state,
        trackEditing: action.payload,
      };



    case actionTypes.LOAD:
      
      var tracks = [];
      if (action.payload.length > 0) {
        tracks = action.payload;
      }
      else {
        tracks = [];
      }
      return {
        ...state,
        trackEditing: null,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        albumName: action.payload[0],
        selectedItem: action.payload[0],
        selectedItems: [],
        noMore: true,
        trackSessionId: action.sessionId,
        sessionItems: { ...state.sessionItems, [action.sessionId]: action.payload },
        selectionType: 1,
        showAddTracks: false
      };

    case actionTypes.GETTING_UPDATE_DATA:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
        selectedItem: action.selectedItem
      };


    case actionTypes.GETTING_ALBUM_UPDATE_DATA:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };


    case actionTypes.ALBUM_DATA_OK:
      return {
        ...state,
        albumEditing: action.payload,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.EMPTY_ITEM:
      return {
        ...state,
        trackEditing: null,
        albumEditing: null,
        query: {
          order: SortTypes.desc,
          orderBy: "date_last_edited",
          page: 0,
          size: defaultResultsPerPage,
          q: "",
          filters: []
        },
      };

      case actionTypes.EMPTY_SESSION:
        return {
          ...state,
          sessionItems: {}
        };

        case actionTypes.EMPTY_ALBUM_TRACK:
          return {
            ...state,
            selectedItem: null
          };

    case actionTypes.UPDATE_DATA_OK:
      return {
        ...state,
        trackEditing: action.payload,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.UPLOADING:      
      return {
        ...state,
        percentage: action.payload
      };

    case actionTypes.SET_ALBUM_NAME:
      return {
        ...state,
        albumData: action.payload
      };

    case actionTypes.UPDATING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };


    case actionTypes.ADD_SESSION_TRACKS:   
      let sessionArray = state.sessionItems[action.sessionId];

      if (!sessionArray)
        sessionArray = [];

      sessionArray = [...action.payload, ...sessionArray];

      return {
        ...state,
        sessionItems: { ...state.sessionItems, [action.sessionId]: sessionArray },
        items: sessionArray,
        currentSessionId: action.currentSessionId
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        noMore: true,
        posting: {
          busy: false,
        },
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
        trackEditing: null,
        selectionType: 1
      };

    case actionTypes.UPLOAD_EDIT_ITEM:
      return {
        ...state,
        trackEditing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.ALBUM_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          editing: null,
          errors: null,
        },
      };

    case actionTypes.UPDATE_OK:

      if (Object.keys(state.sessionItems).length !== 0) {
        let objIndex = state.sessionItems[state.trackSessionId].findIndex((obj => obj.id == action.payload.trackMetadata.dhTrackId));
        state.sessionItems[state.trackSessionId][objIndex].metadata_json = action.payload.trackMetadata;
        state.sessionItems[state.trackSessionId][objIndex].album_metadata = action.payload.albumMetadata;
        return {
          ...state,
          trackEditing: null,
          items: state.sessionItems[state.trackSessionId],
          selectedItem: state.sessionItems[state.trackSessionId][objIndex],
          selectionType: 1,
          refresh: true,
          posting: {
            busy: false,
            message: "",
            errors: null,
          },
        };
      }
      else {
        return {
          ...state,
          trackEditing: null,
          selectionType: 1,
          refresh: true,
          posting: {
            busy: false,
            message: "",
            errors: null,
          },
        };
      }




    case actionTypes.REMOVE_OK:
      let objIndexR = state.sessionItems[state.trackSessionId].findIndex((obj => obj.id == action.payload.data.track_id));
      state.sessionItems[state.trackSessionId].splice(objIndexR, 1);


      return {
        ...state,
        items: state.sessionItems[state.trackSessionId],
        selectedItem: state.sessionItems[state.trackSessionId][objIndexR],
        trackEditing: null,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };
    case actionTypes.SET_PLAYLIST: {
      return {
        ...state,
        playlist: action.payload,
      };
    }
    default:
      return state;
  }
}