import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import {
  __getMessageId,
  __getNotes,
  __getStatus,
} from "../../_clearance/utils/utils";
import _trackFields from "../../_module/definition/trackFields";
import { validateClearanceForm} from "../../util/validations/MlLicFormContentActions";

module = "/mlLic/formcontent/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  POST_PPL_ERROR: "POST_PPL_ERROR" + module,
  REFRESH_ITEMS: "REFRESH_ITEMS" + module,
  REFRESH_CF: "REFRESH_CF" + module,
  EDITING: "EDITING" + module,
  OPENWIZARD: "OPENWIZARD" + module,
  CLEAR_ERRORS: "CLEAR_ERRORS" + module,
  LOAD_HISTORY: "LOAD_HISTORY" + module,
  REMOVE_HISTORY: "REMOVE_HISTORY" + module,
  REMOVE_HISTORY_ALL: "REMOVE_HISTORY_ALL" + module,

  PREVIEW_TAB: "PREVIEW_TAB" + module,
};

export const getCFContent = (id) =>
  new Promise(function (resolve, reject) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    axios
      .get(`${orgID}/mllic/formcontent/${id}`, {
        headers: { orgid: orgID },
      })
      .then((res) => {
        resolve(res?.data?.results ? res.data.results : null);
      })
      .catch((err) => {
        resolve(null);
      });
  });

export const openWizard = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.OPENWIZARD,
    payload: data,
  });
};

export const removeTrackHistory = (cfId, trackId) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_HISTORY,
    payload: {
      cfId:cfId, 
      trackId:trackId
    },
  });
};

export const removeTrackHistoryAll = (cfId) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_HISTORY_ALL,
    payload: {
      cfId:cfId, 
    },
  });
};

export const getTrackHistory = (cfId, trackId, done = null) => (dispatch) => {
  //   if (done) done({}, null);
  // }
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  // dispatch({
  //   type: actionTypes.POSTING,
  //   payload: message,
  // });

  axios
    .post(`${orgID}/mllic/tracks/searchHistory`, { cfId: cfId, trackId:trackId }, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.LOAD_HISTORY,
        payload: {
          cfId: cfId, 
          trackId:trackId,
          history:res.data
        }
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      // dispatch({
      //   type: actionTypes.POST_ERROR,
      //   // payload: { e: err },
      //   payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      // });

      // if (done) done(null, err.response?.data?.name == 'Duplicate Name'?err.response?.data?.name:err.message || "error");
    });
};

export const postItem = (action, message, items, done = null) => (dispatch) => {
  if(action == 'saveform'){
    let errors = validateClearanceForm(items.cf);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      if (done) done(null, errors);
      return;
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mllic/forms/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
        payload:items
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        // payload: { e: err },
        payload: err.response?.data || {
          e: err.message
            ? err.message
            : "Error in creating or updating. please retry",
        },
      });

      if (done) done(null, err);
    });
};

export const refreshitems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH_ITEMS,
    payload: items,
  });
};

export const refreshEdit = (editing) => (dispatch) => {
  dispatch({
    type: actionTypes.EDITING,
    payload: editing,
  });
};

export const refreshCF = (cf, newTracks=[]) => (dispatch) => {


  const {
    mllicformcontent: {
      editing: { clearance: { tLookup} },
    },
  } = store.getState();

  newTracks.forEach(v=>{
    tLookup.push(v);
  })

  let items = [];
  cf.clearance.tracks.forEach(v => {
    let tDetails = tLookup.find(t=>t.id === v) || {};
    let tStatus = __getStatus(cf.clearance, tDetails.id) || { v: "", unconfirmed: true };
    let messageid = __getMessageId(cf.clearance, tDetails.id) || {
      v: "",
    };

    items.push({
      ...tDetails,
      origin: tDetails[_trackFields.musicOrigin.id],
      clearancestatus: tStatus.unconfirmed ? "" : tStatus.v,
      adminOverridden: tStatus.adminOverridden,
      adminOverriddenWithNotes: tStatus.adminOverriddenNotes,
      createdBy: tStatus.by,
      createdAt: tStatus.at,
      messageid: messageid.v,
      trackNotes: (__getNotes(cf.clearance, tDetails.id) || { v: [] }).v,
    });
  });

  cf.clearance.tLookup = tLookup;



  dispatch({
    type: actionTypes.REFRESH_CF,
    payload: { data: cf, items: items },
  });
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

const _initCFItems = (res, done, dispatch) => {
  let items = [];
  res.clearance.tracks.map((v) => {
    let tDetails = res.clearance.tLookup.find((t) => t.id === v) || {};
    let status = __getStatus(res.clearance, tDetails.id) || {
      v: "",
    };
    let messageid = __getMessageId(res.clearance, tDetails.id) || { v: "" };
    items.push({
      ...tDetails,
      origin: tDetails[_trackFields.musicOrigin.id],
      clearancestatus: status.unconfirmed ? "" : status.v,
      adminOverridden: status.adminOverridden,
      adminOverriddenWithNotes: status.adminOverriddenNotes,
      trackNotes: (__getNotes(res.clearance, tDetails.id) || { v:[] }).v,
      messageid: messageid.v,
      createdBy: status.by,
      createdAt: status.at,
    });
  });

  //return { data: res, items: items };
  dispatch({
    type: actionTypes.LOAD,
    payload: { data: res, items: items },
  });

  if (done) done({ data: res, items: items }, null);
};

export const loadcfWithClerance = (cf, done) => (dispatch) => {
  return _initCFItems(cf, done, dispatch);
};

export const clearErrors = (done) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_ERRORS,
    payload: {},
  });
  if (done) done();
};

export const loadcf = (id, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });

  axios
    .get(`${orgID}/mllic/formcontent/${id}`, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        return _initCFItems(res.data.results, done, dispatch);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const setFocusPreview = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.PREVIEW_TAB,
    payload: data,
  });
};

export const addTracKToPPL = (message, items, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mllic/forms/addtoppl`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
    dispatch({
      type: actionTypes.POST_OK,
      payload:items
    });

      if (done) done(res.data, null);

      })
    .catch((err) => {      
      dispatch({
        type: actionTypes.POST_PPL_ERROR,
        // payload: err.response?.data?.e || err.message ? err.message : "Error adding track to PPL.",
        payload: err.response?.data?.e || "Error adding track to PPL.",
      });

      setTimeout(() => {
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: err.response?.data || {
            e: err.message
              ? err.message
              : "Error adding track to PPL.",
          },
        });
      }, 1500);

      if (done) done(null, err);
    });
};
