export const styles = (theme) => ({
    tablePlayNow:{
        fontSize:"1em",
        padding:"5px",
        width:"20px",
        height:"20px",
        "& svg":{
            fontSize:"17px",
        },
        "& .MuiCircularProgress-colorPrimary" :{
            color: "#ffffff",
            width: "15px !important",
            height: "15px !important",
            margin:"0"
        },
    },
    tableNoPermission:{
        fontSize:"1em",
        padding:"5px",
        background: "#313131 !important",
        "& svg":{
            fontSize:"17px",
        },
        "& .MuiCircularProgress-colorPrimary" :{
            color: "#ffffff",
            width: "20px !important",
            height: "20px !important",
        },
    },
    playAllButton:{
        background: "#00a7c1",
          fontSize: "22px",
          width: "30px",
          height: "30px",
          margin: "0 10px",
          "& svg":{
            fontSize:"20px",
        },
        "& .MuiCircularProgress-colorPrimary" :{
            color: "#ffffff",
            width: "20px !important",
            height: "20px !important",
        },
        "&:hover":{
            background: "#008da2",
        }
      },
      noPermission:{
          fontSize:"16px",
      },
      noPermissionIcon:{
         padding:"0",
      }
       
});
