import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";

module = "/tagcloud";
export const actionTypes = {
  OPEN: "OPEN" + module,
  CLOSE: "CLOSE" + module,
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  QUERY: "QUERY" + module,
};

export const open = (cloudField) => (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE,
  });

  setTimeout(() => {
    dispatch({
      type: actionTypes.OPEN,
      payload: cloudField,
    });
  }, 100);
};

export const close = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE,
  });
};

export const lookUp = (query) =>
  new Promise(function (resolve, reject) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    const tId = uuidv4();
    axios
      .post(`${orgID}/tagcloud/lookup`, query, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        if (res.data.tid === tId) {
          resolve(res.data.data);
        }
      })
      .catch((err) => {
        resolve([]);
      });
  });

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });
  
  dispatch({
    type: actionTypes.LOADING,
  });

  axios
    .post(`${orgID}/tagcloud/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};
