import { actionTypes } from "../actions/MLTrackUpdateAction";
import { SortTypes } from "../../util/table-view/TableTypes";
import { defaultResultsPerPage, pagingTotalRelation,  } from "../Types";


const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  trackSessionId: null,
  sessionItems: [],
  selectedItem: {},
  alertMessage: {},
  selectedItems: [],
  selectedAlbumTracks: [],
  selectionType: null,
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  trackEditing: null,
  noMore: true,
  playlist: null,
  currentSessionId: null,
  percentage: 0,
  albumData: null,
  refresh: true,
  showAddTracks: false,
  albumEditing: null,
  sessionId: "",
  sourceIndex: null,
  destIndex: null,
  idList: null,
  query: {
    order: SortTypes.asc,
    orderBy: "position",
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    filters: []
  },
  errors: null,
  do_update: false,
  currentSession: null,
  upload_identifier: null,
  do_reload_session: false,
  selectedTrack: null,
  do_update_selected: false,
  show_uploader: true,
  doGetPreviewData: false,
  isDataLoaded: false,
  contributorsEdit: [],
  prsTrack: null,
  isEditPrs: false,
  prsAction: null,
  queueItems: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
      };
    // case actionTypes.LOADING_MORE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loadingMore: true,
    //     errors: null,
    //   };


    case actionTypes.LOAD_ITEMS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };

    case actionTypes.SET_CONTRIBUTORS:
      return {
        ...state,
        contributorsEdit: action.payload,
      };

    case actionTypes.DATA_LOADED:
      return {
        ...state,
        isDataLoaded: action.payload
      };


    case actionTypes.SET_UPLOAD_IDENTIFIER:
      return {
        ...state,
        upload_identifier: action.payload,
      };

    case actionTypes.SET_S3_IDS:
      return {
        ...state,
        idList: action.payload,
      };

    case actionTypes.DO_UPDATE:
      return {
        ...state,
        do_update: action.payload,
      };


    case actionTypes.REFRESH:
      return {
        ...state,
        refresh: action.status
      };

    case actionTypes.SOURCE_DEST:
      return {
        ...state,
        sourceIndex: action.source,
        destIndex: action.dest
      };

    case actionTypes.SHOW_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.payload
      };

    case actionTypes.SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload
      };

    case actionTypes.ADD_TRACKS_SHOW:
      return {
        ...state,
        showAddTracks: action.status
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
        selectedItem: null,
      };

    case actionTypes.ALBUM_EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };


    case actionTypes.TRACK_EDIT_ITEM:
      return {
        ...state,
        trackEditing: action.payload,
      };



    case actionTypes.LOAD:
      var tracks = [];
      if (action.payload.length > 0) {
        tracks = action.payload;
      }
      else {
        tracks = [];
      }
      return {
        ...state,
        trackEditing: null,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        albumName: action.payload[0],
        selectedItem: action.payload[0],
        selectedItems: [],
        noMore: true,
        trackSessionId: action.sessionId,
        //sessionItems: action.payload,
        selectionType: 1,
        showAddTracks: false,
        isDataLoaded: true,
        posting: { busy: false, message: "", errors: null },

      };

    case actionTypes.GRID_STATUS_UPDATE:
      return {
        ...state,
        items: action.payload
      };


    case actionTypes.GETTING_UPDATE_DATA:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
        selectedItem: action.selectedItem
      };


    case actionTypes.GETTING_ALBUM_UPDATE_DATA:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.SET_SESSION:
      return {
        ...state,
        currentSession: action.payload,
      };

    case actionTypes.SET_SELECTED_ITEM:
      return {
        ...state,
        selectedTrack: action.payload,
        do_update_selected: true,
      };

    case actionTypes.DO_UPDATE_SELECTED:
      return {
        ...state,
        do_update_selected: action.payload
      };

      case actionTypes.SHOW_UPLOADER:
        return {
          ...state,
          show_uploader: action.payload
        };

    case actionTypes.DO_RELOAD_SESSION:
      return {
        ...state,
        do_reload_session: action.payload,
      };


    case actionTypes.ALBUM_DATA_OK:
      return {
        ...state,
        albumEditing: action.payload,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.EMPTY_ITEM:
      return {
        ...state,
        trackEditing: null,
        albumEditing: null,
        selectedItems: [],
        doGetPreviewData: true,
        query: {
          order: SortTypes.desc,
          orderBy: "date_last_edited",
          page: 0,
          size: defaultResultsPerPage,
          q: "",
          filters: []
        },
      };

      case actionTypes.EMPTY_SELECTED_ITEM:
      return {
        ...state,
        trackEditing: null,
        selectedItem: null,
      };

    case actionTypes.EMPTY_SESSION:
      return {
        ...state,
        sessionItems: []
      };

    case actionTypes.SET_PREVIEW:
      return {
        ...state,
        doGetPreviewData: action.payload
      };

    case actionTypes.SET_UPLOAD_TRACKS:
      return {
        ...state,
        sessionItems: [...action.payload, ...state.sessionItems]
      };

    case actionTypes.EMPTY_ALBUM_TRACK:
      return {
        ...state,
        selectedItem: null
      };

    case actionTypes.UPDATE_DATA_OK:
      return {
        ...state,
        sessionItems: [],
        trackEditing: action.payload,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.SET_PRS_TO_EDIT_TRACK:
      return {
        ...state,
        prsTrack: action.payload,
        isEditPrs: action.status,
        prsAction: action.prsAction
      };


    case actionTypes.CONTENT_TRACK_EDIT_ITEM:
      return {
        ...state,
        sessionItems: [],
        trackEditing: action.payload,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.DELETE_DATA_OK:
      return {
        ...state,
        sessionItems: [],
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.UPLOADING:


      let uploadingItems = state.items.map(
        (content, i) => content.s3_id === action.payload.s3_id ?
          { ...content, disply_status: action.payload.disply_status, status: 1 }
          : content);

      return {
        ...state,
        items: uploadingItems,
        //sessionItems: uploadingItems    
      };




    case actionTypes.UPLOAD_COMPLETE:

      let mappedItems = state.items.map(
        (content, i) => content.s3_id === action.payload.s3_id ?
          { ...content, disply_status: action.payload.disply_status, status: 2 }
          : content);

      return {
        ...state,
        items: mappedItems,
        //sessionItems:mappedItems     
      };

    case actionTypes.UPLOAD_ERROR:

      let mappedErorItems = state.items.map(
        (content, i) => content.s3_id === action.payload.s3_id ?
          { ...content, disply_status: action.payload.disply_status, status: 3 }
          : content);

      return {
        ...state,
        items: mappedErorItems,
        //sessionItems:mappedErorItems       
      };


    case actionTypes.SET_ALBUM_NAME:
      return {
        ...state,
        albumData: action.payload
      };

    case actionTypes.UPDATING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.ADD_SESSION_TRACKS:
      let sessionArray = state.sessionItems;

      if (!sessionArray)
        sessionArray = [];

      sessionArray = [...action.payload, ...sessionArray];

      return {
        ...state,
        sessionItems: action.payload,
        items: sessionArray,
        currentSessionId: action.currentSessionId
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        noMore: true,
        posting: {
          busy: false,
        },
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
        trackEditing: null,
        selectionType: 1
      };

    case actionTypes.UPLOAD_EDIT_ITEM:
      return {
        ...state,
        trackEditing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.ALBUM_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          editing: null,
          errors: null,
        },
      };

      case actionTypes.SET_QUEUE_ITEMS:
        return {
          ...state,
         queueItems: action.payload
        };
  

    case actionTypes.UPDATE_OK:
      // if (Object.keys(state.sessionItems).length !== 0) {
      //   let objIndex = state.sessionItems.findIndex((obj => obj.id == action.payload.trackMetadata.dhTrackId));
      //   state.sessionItems[objIndex].metadata_json = action.payload.trackMetadata;
      //   state.sessionItems[objIndex].album_metadata = action.payload.albumMetadata;
      //   return {
      //     ...state,
      //     trackEditing: null,
      //     items: state.sessionItems,
      //     selectedItem: state.sessionItems[objIndex],
      //     selectionType: 1,
      //     refresh: true,
      //     posting: {
      //       busy: false,
      //       message: "",
      //       errors: null,
      //     },
      //   };
      // }
      // else {
        return {
          ...state,
          trackEditing: null,
          selectionType: 1,
          refresh: true,
          posting: {
            busy: false,
            message: "",
            errors: null,
          },
        };
      // }




    case actionTypes.REMOVE_OK:
      let objIndexR = state.sessionItems.findIndex((obj => obj.id == action.payload.data.track_id));
      state.sessionItems.splice(objIndexR, 1);


      return {
        ...state,
        items: state.sessionItems,
        selectedItem: state.sessionItems[objIndexR],
        trackEditing: null,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };
    case actionTypes.SET_PLAYLIST: {
      return {
        ...state,
        playlist: action.payload,
      };
    }
    default:
      return state;
  }
}