//import { PlaylistTypes } from "../../modules/ml-playlist/mylist/Columns";
import { PlaylistTypes } from "../../modules/ml-playlist/mylist/Columns";
import { textLengths } from "../../redux/Types";

export const ValidatePlaylist = (playlistData) => {
    let errors = {}

    if (playlistData) {
        if (!playlistData.name) {
            errors.name = "Should not be empty";
        } else if (playlistData.name && playlistData.name.length > textLengths.TEXT) {
            errors.name = "Sorry, the name you entered is too long. Please try a shorter name";
        } else if (playlistData.notes && playlistData.notes.length > textLengths.TEXT) {
            errors.notes = "Sorry, the note you entered is too long. Please try a shorter note";
        }
        // else if (playlistData.type === PlaylistTypes.public && !playlistData.categoryId) {
        //      errors.categoryId = "Sorry, the category is required";
        // }
    }

    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}