import { UPDATE_DOWNLOAD_LIST, ADD_DOWNLOADING_LIST, REMOVE_DOWNLOADING_LIST, CLEAR_DOWNLOAD_LIST } from "./../Types";

const initialState = {
  tracksDownloading: [],
  trackDownloads: new Map(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_DOWNLOAD_LIST:
      return {
        ...state,
        trackDownloads: new Map(),
        tracksDownloading: [],
      };

    case UPDATE_DOWNLOAD_LIST:
      return {
        ...state,
        trackDownloads: state.trackDownloads.set(action.payload.id, action.payload.downloads),
        tracksDownloading: state.tracksDownloading.filter(r=> r !== action.payload.id),
      };

    case ADD_DOWNLOADING_LIST:
      return {
        ...state,
        tracksDownloading: [...state.tracksDownloading, action.payload.id],
      };

    case REMOVE_DOWNLOADING_LIST:
      return {
        ...state,
        tracksDownloading: state.tracksDownloading.filter(r=> r !== action.payload.id),
      };

    default:
      return state;
  }
}
