import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { validateCreatePPL } from "../../util/validations/content-validations/PPLValidation";

module = "/mllibrary";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
};

var tId = null;

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};
export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const setQuery = (query, done = null) => (dispatch) => {  
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};



export const LabelPost = (action, message, item, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  if (item) {
    let errors = validateCreatePPL(item);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  }); 

  axios
  .post(`${orgID}/ppllabels/addPplLabel`, item)
  .then((res) => {    
    dispatch({
      type: actionTypes.POST_OK,
      payload: item
    });           
  if (done) done(res.data, null);
  })
  .catch((err) => {
    dispatch({
      type: actionTypes.POST_ERROR,
      payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
    }); 
    if (done) done(null, err.message || "error");
  });
}

export const LabelEdit = (action, message, item, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  if (item) {
    let errors = validateCreatePPL(item);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  }); 

  axios
  .post(`${orgID}/ppllabels/updatePpl`,item)
  .then((res) => {    
    dispatch({
      type: actionTypes.POST_OK,
      payload: item
    });           
  if (done) done(res.data, null);
  })
  .catch((err) => {
    dispatch({
      type: actionTypes.POST_ERROR,
      payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
    }); 
    if (done) done(null, err.message || "error");
  });
}


export const search = (query) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  tId = uuidv4();
  if(!query.orderBy){
    query.orderBy = 'date_created';
  } 
  var _apiEndpoint = `${orgID}/ppllabels/search`;
  const options = {
    headers: {
      tid: tId,
    },
  };
  dispatch({
    type: actionTypes.LOADING,
  });

  axios
      .post(_apiEndpoint, query, options)
      .then((res) => {        
              dispatch({
              type: actionTypes.LOAD,
              payload: res.data.results,
              total: res.data.total,
              totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
              });  
      })
      .catch((err) => {  
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });   
      });
};
