import {
  T_LeafOptionsMultiOrdered,
  T_Major,
  T_LeafOptionsSingle,
  T_LeafOptionsMulti,
} from "./ModulePropTypes";
import { PT_Setting } from "./ModulePermitTypes";
import trackfields from "./trackFields";
import getOrgSettings from "../../util/sys-calls/getOrgSettings";
import orgSettingType from "./orgSettingType";

export const _settingMusicResultSingleViewCols = {
  id: "sr1",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(trackfields).map((key) => {
      if (!trackfields[key].sys) nonesys[key] = trackfields[key];
    });
    return nonesys;
  },
  default: [
    trackfields.trackTitle.id,
    trackfields.prodArtist.id,
    trackfields.performer.id,
    trackfields.prodName.id,
    trackfields.isrc.id,
    trackfields.licenseStatus.id,
  ],
};

export const _settingMusicResultMultiViewCols = {
  id: "sr2",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(trackfields).map((key) => {
      if (!trackfields[key].sys) nonesys[key] = trackfields[key];
    });
    return nonesys;
  },
  default: [
    trackfields.trackTitle.id,
    trackfields.prodArtist.id,
    trackfields.performer.id,
    trackfields.prodName.id,
    trackfields.recordLabel.id,
    trackfields.isrc.id,
    trackfields.catNo.id,
    trackfields.duration.id,
    trackfields.arranger.id,
    trackfields.prodRelease.id,
    trackfields.keywords.id,
    trackfields.genres.id,
    trackfields.dateCreated.id,
    trackfields.dateLastEdited.id,
  ],
};

export const _settingMusicResultRankingType = {
  id: "sr3",
  permitType: PT_Setting,
  type: T_LeafOptionsSingle,
  sysCall: getOrgSettings,
  sysCallParam: orgSettingType.trackranking.id,
  default: "",
  removable:true
};

export const _settingMusicResult = {
  id: "settingMusicResult",
  permitType: PT_Setting,
  type: T_Major,
  modules: [
    _settingMusicResultSingleViewCols,
    _settingMusicResultMultiViewCols,
    _settingMusicResultRankingType,
  ],
};
