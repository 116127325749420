import { actionTypes } from "../actions/SMLContentTrackAction";
import { SortTypes } from "../../util/table-view/TableTypes";
import { resultViews } from "../../_module/definition/MlLibraries";
import {
  _searchMain,
  _searchTag,
  _searchPersonal,
} from "../../_module/definition/Settings";
import trackOrigins from "../../_module/definition/trackOrigins";
import trackAllFileds from "../../_module/definition/trackAllFileds";
import { defaultResultsPerPage, pagingTotalRelation, pagingType } from "../Types";
import { paging_type } from "../../App";

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  selectionType: null,
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  trackEditing: null,
  noMore: true,
  resultView: resultViews.single.id,
  discreteIsrcs: [],
  smTrackData: null,
  trackCount: null,
  query: {
    advSearch: _searchMain.id,
    order: SortTypes.asc,
    orderBy: null,
    page: 0,
    size: defaultResultsPerPage,
    origin: trackOrigins.originall.val,
    q: "",
    qfs: trackAllFileds,
    filters: [],
    all: { q: "", f: "all" },
    tagAll: { q: "", f: "all" },
  },
  errors: null,
  originalItems: [],
  postUpdating: false,
  originalItems: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.POST_UPDATE:
      return {
        ...state,
        items: action.payload.items,
        originalItems: action.payload.originalItems,
        postUpdating: false,
      };

    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
        discreteIsrcs: [],
        editing: {},
        trackEditing: {},
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
        trackEditing: action.payload,
      };

    case actionTypes.SET_TRACK_DATA:
      return {
        ...state,
        smTrackData: action.payload,
      };

    case actionTypes.EMPTY_ITEM:
      return {
        ...state,
        editing: {},
        selectedItem: {},
        trackEditing: {},
      };

    case actionTypes.GETTING_UPDATE_DATA:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.UPDATING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.TRACK_EDIT_ITEM:
      return {
        ...state,
        trackEditing: action.payload,
      };

    case actionTypes.UPDATE_DATA_OK:
      return {
        ...state,
        editing: action.payload,
        trackEditing: action.payload,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.LOADING_MORE:
      return {
        ...state,
        loading: false,
        loadingMore: true,
        errors: null,
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        selectedItems: [],
        editing: {},
        trackEditing: {},
        trackCount: action.total,
        noMore: false,
        postUpdating: action.postUpdating,
        originalItems: action.payload.originalItems,
      };

    case actionTypes.POST_UPDATE:
      return {
        ...state,
        items: action.payload.items,
        originalItems: action.payload.originalItems,
        postUpdating: false,
      };

    case actionTypes.LOAD_MORE:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: paging_type === pagingType.PAGING ? action.payload : state.items.concat(action.payload),
        totalItems: action.total,
        noMore: false,
      };

    case actionTypes.LOAD_NOMORE:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: paging_type === pagingType.PAGING ? action.payload : state.items.concat(action.payload),
        totalItems: action.total,
        noMore: true,
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        noMore: true,
      };

    case actionTypes.CLEAR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        selectedItem: {},
        items: [],
        noMore: true,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
        editing: null,
        trackEditing: null,
        selectionType: 2,
      };
    case actionTypes.RESULT_VIEW:
      return {
        ...state,
        resultView: action.payload,
      };
    case actionTypes.ADV_SEARCH:
      return {
        ...state,
        query: { ...state.query, advSearch: action.payload },
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.UPDATE_OK:
      return {
        ...state,
        trackEditing: null,
        editing: null,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.SET_MESSAGE:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.LOAD_QUERY:
      if (!action.payload.advSearch) {
        action.payload.advSearch = _searchMain.id;
        if (action.payload?.all?.q || action.payload?.filters?.length > 0) {
          action.payload.advSearch = _searchPersonal.id;
        } else if (action.payload?.tagAll?.q) {
          action.payload.advSearch = _searchTag.id;
        }
      }

      return {
        ...initialState,
        query: action.payload,
      };

    default:
      return state;
  }
}
