import { textLengths } from "../../redux/Types";

export const ValidateStatus = (statusData) => {
    let errors = {}
    if (statusData) {
        if (!statusData.name) {
            errors.name = "Should not be empty";
        } else if (statusData.name && statusData.name.length > textLengths.TEXT) {
            errors.name = "Sorry, the name you entered is too long. Please try a shorter name";
        }

        if (statusData.notes && statusData.notes.length > textLengths.TEXT) {
            errors.notes = "Sorry, the message you entered is too long. Please try a shorter message";
        }

        if (!statusData.weight) {
            errors.weight = "Should not be empty";
        }

        if (statusData.weight && statusData.weight <= 0 ) {
            errors.weight = "Minimum weight value should be 1";
        }
    }
    
    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}