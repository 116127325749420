import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { defaultResultsPerPage } from "../../../../redux/Types";
import { SortTypes } from "../../../../util/table-view/TableTypes";
import { ValidateContentRequest } from "../../../../util/validations/MessageComposeValidation";
import { contentRequestStatus, contentRequestTypes, messageTypes } from "modules/messages/Const";
module = "/contentRequest";

export const actionTypes = {

  NEW_COUNT: "NEW_COUNT" + module,
  NEW_UNREAD_COUNT: "NEW_UNREAD_COUNT" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  EDIT_ITEMS_SAVECB : "EDIT_ITEMS_SAVECB" + module,
  CLOSE_MESSAGECB : "CLOSE_MESSAGECB" + module,
  UPDATE_CONTENTREQ_STATUS : "UPDATE_CONTENTREQ_STATUS" + module,

  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,

  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,

  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,

  REFRESH_ITEMS: "REFRESH_ITEMS" + module,

  LINK_CONTENT_REQUEST: "LINK_CONTENT_REQUEST" + module,
  LOADING_LINK_CREQUEST: "LOADING_LINK_CREQUEST" + module,
  LOAD_LINK_CREQUEST: "LOAD_LINK_CREQUEST" + module,
  ERROR_LINK_CREQUEST: "ERROR_LINK_CREQUEST" + module,
  LOAD_HISTORY: "LOAD_HISTORY" + module,
  REMOVE_HISTORY: "REMOVE_HISTORY" + module,
};


export const setLinkwithContentRequest = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.LINK_CONTENT_REQUEST,
    payload: data,
  });
};

export const refreshItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH_ITEMS,
    payload: items,
  });
};


export const setQuery = (query, done = null) => (dispatch) => {

    let filter = [{operator: "equals", value: [messageTypes.CONTENT_REQUEST], field: "type"}]
    if(query?.filters?.length > 0){
        query.filters.concat(filter);
    }else{
        query.filters = filter;
    }

    dispatch({
      type: actionTypes.QUERY,
      payload: query,
    });
  
    if (done) done();
  };
  
  export const selectItems = (items) => (dispatch) => {
    dispatch({
      type: actionTypes.SELECT_ITEMS,
      payload: items,
    });
  };
  
  export const focusItem = (item) => (dispatch) => {
    dispatch({
      type: actionTypes.FOCUS_ITEM,
      payload: item,
    });
  };

export const search = (query) => (dispatch) => {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
  
    const tId = uuidv4();
    dispatch({
      type: actionTypes.LOADING,
    });

    let filter = [{operator: "equals", value: [messageTypes.CONTENT_REQUEST], field: "type"}]
    if(query?.filters?.length > 0){
        query.filters.concat(filter);
    }else{
        query.filters = filter;
    }
  
    axios
      .post(`${orgID}/messages/searchContentRequest`, query, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        if (res.data.tid === tId) {
          dispatch({
            type: actionTypes.LOAD,
            payload: res.data.results,
            total: res.data.total,
            unread: res.data.totalUnreadCount
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });
      });
  };
export const linkCRequestSearch = (type = contentRequestTypes.TRACK_INSTRUMENTAL) => (dispatch) => {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
  
    const tId = uuidv4();
    dispatch({
      type: actionTypes.LOADING_LINK_CREQUEST,
    });

    let query = {
      order: SortTypes.desc,
      orderBy: "updatedAt",
      page: 0,
      size: defaultResultsPerPage,
      q: "",
      filters: [
        {operator: "equals", value: [messageTypes.CONTENT_REQUEST], field: "type"},
        {operator: "equals", value: [contentRequestStatus.ACKNOWLEDGED], field: "cStatus"},
        {operator: "equals", value: [type], field: "cContentType"},
      ],
    }
  
    axios
      .post(`${orgID}/messages/searchContentRequest`, query, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        if (res.data.tid === tId) {
          dispatch({
            type: actionTypes.LOAD_LINK_CREQUEST,
            payload: res.data.results,
            total: res.data.total,
            unread: res.data.totalUnreadCount
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR_LINK_CREQUEST,
          payload: { e: err },
        });
      });
  };

  export const postItem = (action, message, item, done = null) => (dispatch) => {

    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    const tId = uuidv4();
  
    dispatch({
      type: actionTypes.POSTING,
      payload: message,
    });
  
    axios
      .post(`${orgID}/messages/${action}`, item, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        //if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.POST_OK,
        });
  
        if (done) done(res.data, null);
        //}
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.POST_ERROR,
          // payload: { e: err },
          payload: err.response?.data || {
            e: err.message
              ? err.message
              : "Error in updating. please retry",
          },
        });
  
        if (done) done(null, err);
      });
  };

  export const removeHistory = (messageId) => (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_HISTORY,
      payload: messageId,
    });
  };

  export const getMessageHistory = (messageId, done = null) => (dispatch) => {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    const tId = uuidv4();
  
    axios
      .post(`${orgID}/messages/searchContentRequestHistory`, { id: messageId }, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        //if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD_HISTORY,
          payload: {
            messageId:messageId,
            history:res.data
          }
        });
  
        if (done) done(res.data, null);
        //}
      })
      .catch((err) => {
      });
  };