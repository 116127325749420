import { T_Major, T_Branch } from "_module/definition/ModulePropTypes";
import { PT_System } from "../definition/ModulePermitTypes";
import {
  T_LeafOptionsMulti,
  T_LeafOptionsMultiOrdered,
  T_LeafOptionsSingle,
} from "../definition/ModulePropTypes";
export const _newModuleInstance = (module) => {
 
  if (module.type === T_Major || module.type === T_Branch || module.type === PT_System) {
    let lreturn = { id: module.id };
    module.modules.map((v) => {
      lreturn[v.id] = _newModuleInstance(v);
    });
    return lreturn;
  } else if (
    module.type == T_LeafOptionsMulti ||
    module.type == T_LeafOptionsMultiOrdered
  ) {
    return { id: module.id, v: module.default ? module.default : [] };
  } else if (module.type == T_LeafOptionsSingle) {
    return { id: module.id, v: module.default ? module.default : "" };
  } else return { id: module.id, v: module.default ? module.default : false };
};
