import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
module = "/uploadTrackContent/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_PLAYLIST: "SET_PLAYLIST" + module,
  ADD_SESSION_TRACKS: "ADD_SESSION_TRACKS" + module,
  UPDATE_OK: "UPDATE_OK" + module,
  UPDATING: "UPDATING" + module,
  UPLOADING: "UPLOADING" + module,
  SET_ALBUM_NAME: "SET_ALBUM_NAME" + module,
  REFRESH: "REFRESH" + module,
  GETTING_UPDATE_DATA: "GETTING_UPDATE_DATA" + module,
  UPDATE_DATA_OK: "UPDATE_DATA_OK" + module,
  EMPTY_ITEM: "EMPTY_ITEM" + module,
  UPLOAD_EDIT_ITEM: "UPLOAD_EDIT_ITEM" + module,
  TRACK_EDIT_ITEM: "TRACK_EDIT_ITEM" + module,
  ALBUM_OK: "ALBUM_OK" + module,
  ALBUM_EDIT_ITEM: "ALBUM_EDIT_ITEM" + module,
  ADD_TRACKS_SHOW: "ADD_TRACKS_SHOW" + module,
  REMOVE_OK: "REMOVE_OK" + module,


};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.ALBUM_EDIT_ITEM,
    payload: item,
  });
};

export const addTracksShow = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_TRACKS_SHOW,
    status: status,
  });
};

export const uploadEditItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.UPLOAD_EDIT_ITEM,
    payload: item,
  });
};

export const addTracksToAlbum = (action, message, item, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/uploads/albums/addTracksToAlbum`, item)
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_OK,
        payload: item
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err,
      });
      if (done) done(null, err.message || "error");
    });
}

export const albumPost = (action, message, item, selectedAlbumTracks, sessionId, done = null) => (dispatch) => {

  item.selectedTracks = selectedAlbumTracks;
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/uploads/session/uploadAlbum`, { 'albumdata': item, 'sessionId': sessionId })
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_OK,
        payload: item
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err,
      });
      if (done) done(null, err.message || "error");
    });
}

export const albumEdit = (action, message, item, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/uploads/session/editAlbum`, { 'albumdata': item })
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_OK,
        payload: item
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err,
      });
      if (done) done(null, err.message || "error");
    });
}

export const saveItem = (items, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/save`)
    .then((res) => {
      // dispatch({
      // type: actionTypes.LOAD_EDITABLE_PL,
      // payload: res?.data?.results ? res.data.results : []
      // });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });

};

export const checkAndCreateAlbum = (item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/checkAndCreateAlbum`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      done(res.data, null);
    })
    .catch((err) => {
      done(null, err.message || "error");
    });
}

export const trackEditItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.TRACK_EDIT_ITEM,
    payload: item,
  });
};

export const getSessionById = (id, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/getSessionById`, id, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message)

      if (done) done(null, err.message || "error");
    });
}

export const addSessionItems = (action, message, items, sessionId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();


  dispatch({
    type: actionTypes.LOAD,
    payload: items,
    sessionId: sessionId
  });
};

export const updateUploadTrack = (message, item, done = null) => (dispatch) => {

  alert('REMOVE')

  const {user}  = store.getState();

  //Track Setting
  var allTags = []

  if(item.trackMetadata.org_adminTags){
    item.trackMetadata.org_adminTags.forEach((tag) => {
      allTags.push({ 'Type': 'BBC_ADMIN_TAG', 'Value': tag });
    })
  }

  if(item.trackMetadata.org_userTags)
  {
    item.trackMetadata.org_userTags.forEach((tag) => {
      allTags.push({ 'Type': `BBC_USER_TAG_${user.id}`, 'Value': tag });
    })
  }

  if(item.trackMetadata.org_admin_notes ){
    allTags.push({ 'Type': 'BBC_ADMIN_NOTES', 'Value': item.trackMetadata.org_admin_notes });
  }
  item.trackMetadata.orgTags =  allTags ;


  //Album Setting
  var album_allTags = []
  if(item.albumMetadata.org_album_adminTags){
    item.albumMetadata.org_album_adminTags.forEach((tag) => {
      album_allTags.push({ 'Type': 'BBC_ADMIN_TAG', 'Value': tag });
    })
  }
  
  if(item.albumMetadata.org_album_userTags){
    item.albumMetadata.org_album_userTags.forEach((tag) => {
      album_allTags.push({ 'Type': `BBC_USER_TAG_${user.id}`, 'Value': tag });
    })
  }
  
  if(item.albumMetadata.org_album_admin_notes){
    album_allTags.push({ 'Type': 'BBC_ADMIN_NOTES', 'Value': item.albumMetadata.org_album_admin_notes });
  }
  item.albumMetadata.album_orgTags =  album_allTags;



  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(
      `${orgID}/contentadmin/UpdateTrack`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });

}

export const updateTrack = (message, item, albumItem, isAlbumAlbum, done = null) => (dispatch) => {
  const tId = uuidv4();
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(`${orgID}/uploads/tracks/trackUpdate`, { 'trackData': item, 'albumdata': albumItem, 'isAlbumEdit': isAlbumAlbum }, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_OK,
        payload: item
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err.message || "error");
    });
}

export const emptyItem = () => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_ITEM,
  });
};



export const RemoveTrack = (message, item, done = null) => (dispatch) => {

  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(
      `${orgID}/uploads/tracks/removeTrack`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};


export const RemoveAlbum = (message, item, done = null) => (dispatch) => {

  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(
      `${orgID}/uploads/tracks/removeAlbum`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};


export const addSessionTracks = (action, message, items, sessionId,s3Token, done = null) => (dispatch) => {

  alert(2)

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.ADD_SESSION_TRACKS,
    payload: items,
    sessionId: sessionId,
    currentSessionId: sessionId
  });



  items.forEach(track => {
    //let _mlTrackId = uuidv4();


    let _Track = {
      name: track.track_name.replace(/\.[^/.]+$/, ""),
      extention: track.track_name.split('.').pop(),
      data: track.data.split(',')[1],
      type: track.track_type,
      sessionId: sessionId,
      size: track.size,
      s3Id: track.s3_id,
      mlTrackid: track.id,
      s3Bucket: 'bbc-ml-upload',
      artwork: track.artwork != '' ? track.artwork.split(',')[1] : '',
      metadata_json: track.metadata_json,
      mlTrackId: track.id,
      dhTrackJson: track.dh_track_metadata,
      upload_id: track.upload_id
    };

    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (percentCompleted < 100)
          dispatch({
            type: actionTypes.UPLOADING,
            payload: percentCompleted,
          });
        track.status = "Uploading (" + percentCompleted + "%)";
      }
    }

    //======================================================================
    var numberOfAjaxCAllPending = 0;
    axios.interceptors.request.use(function (config) {
      numberOfAjaxCAllPending++;
      console.log("------------  Ajax req pending", numberOfAjaxCAllPending);// do not remove 
      if (numberOfAjaxCAllPending == 0) {
        dispatch({
          type: actionTypes.REFRESH,
          status: true
        });
      }
      else {
        dispatch({
          type: actionTypes.REFRESH,
          status: false
        });
      }
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
      numberOfAjaxCAllPending--;
      console.log("------------  Ajax res pending", numberOfAjaxCAllPending); // do not remove

      if (numberOfAjaxCAllPending == 0) {
        dispatch({
          type: actionTypes.REFRESH,
          status: true
        });
      }
      else {
        dispatch({
          type: actionTypes.REFRESH,
          status: false
        });
      }
      return response;
    }, function (error) {
      numberOfAjaxCAllPending--;
      return Promise.reject(error);
    });

    //=====================================================

    axios
      .post(`${orgID}/uploads/tracks/upload`, _Track, config, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        if (res.data == "EXIST") {
          track.status = "File Exist"
        }
        else {
          track.status = "Uploading"
        }
        dispatch({
          type: actionTypes.POST_OK
        });
        if (done) done(res.data, null);
      })
      .catch((err) => {
        track.status = "Error Uploading"
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });
        if (done) done(null, err.message || "error");
      });
  });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};


export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};


export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};




export const searchAlbums = (query, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  query.orderBy = 'date_last_edited';


  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/albums/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
        if (done) done(res.data.results, null);
      
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const search = (query, sessionId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  if (sessionId) {
    query.filters = [];
    query.filters = [...query.filters, { 'field': 'session_id', 'operator': 'equals', 'value': [sessionId] }]
  }
  query.orderBy = 'date_last_edited';

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/tracks/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        res.data.results.forEach((data) => {
          data.status = "Created"
        });
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const getAlbumById = (albumId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/getUploadAlbumById/${albumId}`, albumId, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.SET_ALBUM_NAME,
          payload: res.data.results,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const searchAlbumTracks = (query, sessionId, done = null) => (dispatch) => {


  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  query.orderBy = 'id';
  if (sessionId) {
    query.filters = [...query.filters, { 'field': 'session_id', 'operator': 'equals', 'value': [sessionId] }]
  }

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/session/getUploadAlbumTracks`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

