import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { ValidateAddUser } from "../../../../util/validations/MlAdminUserActionsValidation";

module = "/smSysAdmin/user/";

export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
};

var tId = null;

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const postItem = (action, message, items, done = null) => (dispatch) => {
  if (items.user) {
    let errors = ValidateAddUser(items.user);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`/sysadmin/user/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
        payload: `${action == "archiveuser"? 'Archive user': action == "inviteuser"? 'Invite user': action == "restoreuser"? 'Restore user': action == "removeabuseflag"? 'Remove abuse flag': action } successful`,
      });
      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        // payload: { e: err },
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });
      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  // console.log("setQuery", query);
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  //console.log("selectItems", items);
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};
export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.LOADING,
  });

  tId = uuidv4();

  axios
    .post(`/sysadmin/user/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};
