import { T_Major, T_LeafBool } from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";
import { _metadataMain } from "./Settings";

export const _mlPlPromoAdmin = {
  id: "mlPlPromoAdmin",
  type: T_LeafBool,
  default: false,
};

// export const _mlPlaylistsSendtoPlayout = {
//   id: "mlPlaylistsSendtoPlayout",
//   type: T_LeafBool,
//   default: true,
// };

export const _mlPlaylistsShare = {
  id: "mlPlaylistsShare",
  type: T_LeafBool,
  default: true,
};

export const _mlPlaylistsAdd = {
  id: "mlPlaylistsAdd",
  type: T_LeafBool,
  default: true,
};

export const _mlPlaylistsDownload = {
  id: "mlPlaylistsDownload",
  type: T_LeafBool,
  default: true,
};

export const _mlPlaylists = {
  id: "mlPlaylists",
  permitType: PT_Org,
  type: T_Major,
  modules: [
    _mlPlPromoAdmin,
    _mlPlaylistsAdd,
    _mlPlaylistsShare,
    _mlPlaylistsDownload,
    //_mlPlaylistsSendtoPlayout,
  ],
};
