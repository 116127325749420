import { T_LeafBool, T_Major } from "./ModulePropTypes";
import { PT_System } from "./ModulePermitTypes";

export const _manageUsers = {
  id: "manageUsers",
  type: T_LeafBool,
  default: true,
};

export const _seeAppLogs = {
  id: "canSeeLogs",
  type: T_LeafBool,
  default: true,
};

export const _manageOrganizations = {
  id: "manageOrganizations",
  type: T_LeafBool,
  default: true,
};



export const _smSysAdmin = {
  id: "smSysAdmin",
  permitType: PT_System,
  type: T_Major,
  modules: [_manageOrganizations, _manageUsers, _seeAppLogs],
};
