import { textLengths } from "../../redux/Types";

export const validateClearanceForm = (data) => {
    let errors = {};
    if (data) {
      if (!data.name.length) {
        errors.name = " Name should not be empty.";
      }
      else if (data.name?.length && data.name.length > textLengths.TEXT) {
        errors.name = "Name should be less than 256 characters.";
      }
      // if (data.notes?.length && data.notes.length > textLengths.TEXT) {
      //   errors.notes = "Clearance form notes should be less than 256 characters.";
      // }
      if (!data.genre) {
        errors.genre = "Genre should not be empty.";
      }
      if(data.refNo){
        if (data.refNo.length > textLengths.SMALL) {
            errors.refNo = "Clearance form ref no length is limited to 10 characters.";
          }
      }
      if (data.synopsis?.length && data.synopsis.length > textLengths.MEDIUMTEXT) {
        errors.synopsis = "Synopsis should be less than 500 characters.";
      }
      if (data.notes?.length && data.notes.length > textLengths.MEDIUMTEXT) {
        errors.notes = "Clearance form notes should be less than 500 characters.";
      }
    }
    if (Object.entries(errors).length === 0) {
      return null;
    } else {
      return errors;
    }
};