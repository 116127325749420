import { T_LeafBool, T_Branch, T_Major } from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";

export const _canSeeLog = {
  id: "canSeeLog",
  type: T_LeafBool,
  default: true,
};




export const _smLog = {
  id: "smLog",
  permitType: PT_Org,
  type: T_Major,
  modules: [],
};
