import { PageTableType } from "../redux/Types";

export const getAriaLabelForSelectCheckBoxAll = (type) => {
  let label = "Select all ";

  switch (type) {
    case PageTableType.CLEARANCE_FORMS:
      return label + "clearance forms";
    case PageTableType.ADMIN_USERS:
      return label + "users";
    case PageTableType.ADMIN_ROLES:
      return label + "roles";
    case PageTableType.ADMIN_SETTINGS:
      return label + "settings";
    case PageTableType.REPORTS:
      return label + "reports";
    case PageTableType.ACTIVITY_LOG:
      return label + "activities";
    case PageTableType.SEARCH_TRACKS:
      return label + "tracks";
    case PageTableType.SEARCH_ALBUMS:
      return label + "albums";
    default:
      return label;
  }
};

export const getAriaLabelForTableRowCheckBox = (type, value) => {
  let label = "Select ";

  switch (type) {
    case PageTableType.CLEARANCE_FORMS:
      return label + value + " clearance form";
    case PageTableType.ADMIN_USERS:
      return label + value + " user";
    case PageTableType.ADMIN_ROLES:
      return label + value + " role";
    case PageTableType.ADMIN_SETTINGS:
      return label + value + " setting";
    case PageTableType.REPORTS:
      return label + value + " report";
    case PageTableType.ACTIVITY_LOG:
      return label + value + " activity";
    default:
      return label + value;
  }
};

export const getAriaLabelForColumnThumb = (type) => {
  let label = "Avatar of ";
  let labelArtWork = "Art work of ";

  switch (type) {
    case PageTableType.ADMIN_USERS:
      return label + "user";
    case PageTableType.CLEARANCE_FORM_TRACKS:
    case PageTableType.CLEARANCE_TRACKS:
    case PageTableType.SEARCH_TRACKS:
    case PageTableType.SEARCH_ALBUM_TRACKS:
    case PageTableType.MYPLAYLIST_TRACKS:
    case PageTableType.UPLOAD_TRACKS:
      return labelArtWork + "track";
    case PageTableType.SEARCH_ALBUMS:
      return labelArtWork + "album";
    case PageTableType.MYPLAYLIST:
      return labelArtWork + "my playlist";
    case PageTableType.PLAYLIST_PROMOS:
      return labelArtWork + "promo playlist";
    default:
      return label;
  }
};

export const getAriaLabelForColumnPlay = (type) => {
  let label = "Play button for ";

  switch (type) {
    case PageTableType.CLEARANCE_FORM_TRACKS:
    case PageTableType.CLEARANCE_TRACKS:
    case PageTableType.SEARCH_TRACKS:
    case PageTableType.SEARCH_ALBUM_TRACKS:
    case PageTableType.MYPLAYLIST_TRACKS:
    case PageTableType.UPLOAD_TRACKS:
      return label + "track";
    default:
      return label;
  }
};

export const getAriaLabelForColumnMenu = (type) => {
  let label = "More action for ";

  switch (type) {
    case PageTableType.CLEARANCE_FORMS:
      return label + "clearance form";
    case PageTableType.CLEARANCE_FORM_TRACKS:
    case PageTableType.CLEARANCE_TRACKS:
      return label + "clearance track";
    case PageTableType.ADMIN_USERS:
      return label + "user";
    case PageTableType.ADMIN_ROLES:
      return label + "role";
    case PageTableType.ADMIN_SETTINGS:
      return label + "settings";
    case PageTableType.LICENCING_ADMIN_RULES:
      return label + "rules";
    case PageTableType.LICENCING_ADMIN_STATUSES:
      return label + "statuses";
    case PageTableType.LICENCING_ADMIN_PARAMETERS:
      return label + "parameters";
    case PageTableType.ADMIN_PLAYLIST_CAT:
      return label + "playlist category";
    case PageTableType.ADMIN_ACTIVITY_LIMIT:
      return label + "activity limitation";
    case PageTableType.SEARCH_TRACKS:
      return label + "search tracks";
    case PageTableType.SEARCH_ALBUM_TRACKS:
      return label + "album tracks";
    case PageTableType.MYPLAYLIST:
      return label + "my playlist";
    case PageTableType.MYPLAYLIST_TRACKS:
      return label + "track";
    case PageTableType.PLAYLIST_PROMOS:
      return label + "promo playlist";
    case PageTableType.UPLOAD_TRACKS:
      return label + "upload track";
    case PageTableType.REPORTS:
      return label + "report";
    case PageTableType.ACTIVITY_LOG:
      return label + "activity";
    case PageTableType.SEARCH_ALBUMS:
      return label + "album";
    case PageTableType.ADMIN_GROUPS:
      return label + "group";
    case PageTableType.CONTENT_ADMIN:
      return label + "content admin";
    default:
      return label;
  }
};
