import {
  T_LeafBool,
  T_LeafOptionsMulti,
  T_LeafOptionsMultiOrdered,
  T_Major,
  T_LeafOptionsSingle,
} from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";
import {
  _settingSearchAllFields,
  _settingSearchAllTagTypes,
} from "./SettingMusicSearch";
import { _metadataMain } from "./Settings";
import getOrgSettings from "../../util/sys-calls/getOrgSettings";
import orgSettingType from "./orgSettingType";

export const _trackMetadataConfig = {
  id: "mc1",
  permitType: PT_Org,
  type: T_LeafOptionsSingle,
  sysCall: getOrgSettings,
  sysCallParam: orgSettingType.trackmetadata.id,
  default: "4",
};

export const _albumMetadataConfig = {
  id: "mc2",
  permitType: PT_Org,
  type: T_LeafOptionsSingle,
  sysCall: getOrgSettings,
  sysCallParam: orgSettingType.albummetadata.id,
  default: "8",
};

export const _canSeeAdminNotes = {
  id: "mc3",
  permitType: PT_Org,
  type: T_LeafBool,
  default: false,
};

export const _canDownloadMetadataXML = {
  id: "mc4",
  permitType: PT_Org,
  type: T_LeafBool,
  default: false,
};

export const _mlMetadata = {
  id: "mlMetadata",
  permitType: PT_Org,
  type: T_Major,
  modules: [_trackMetadataConfig, _albumMetadataConfig, _canSeeAdminNotes,_canDownloadMetadataXML],
};
