import {
  LOADING_DATA,
  SET_MAINPAGE_ITEMS,
  APPEND_MAINPAGE_ITEMS,
  SET_MAINPAGE_SELECTED_ITEM,
  SET_MAINPAGE_SELECTED_ITEMS,
  UNSET_MAINPAGE_ITEMS,
  UNSET_MAINPAGE_SELECTED_ITEM,
  UNSET_MAINPAGE_SELECTED_ITEMS,
  SET_RECENT_PL,
  POST_ITEM,
  SET_LIBRARY_SEARCH_CONFIG,
} from "../Types";

const initialState = {
  items: [],
  selectedItem: {},
  selectedItems: [],
  recntpls: [],
  loading: false,
  librarySearchConfig: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_MAINPAGE_ITEMS:
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    case APPEND_MAINPAGE_ITEMS:
      return {
        ...state,
        items: state.items.concat(action.payload),
      };
    case SET_MAINPAGE_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    case SET_MAINPAGE_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case UNSET_MAINPAGE_ITEMS:
      return {
        ...state,
        items: [],
        loading: false,
      };
    case UNSET_MAINPAGE_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: {},
      };
    case UNSET_MAINPAGE_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: [],
      };
    case SET_RECENT_PL:
      return {
        ...state,
        recntpls: action.payload,
      };
    case POST_ITEM:
      return {
        ...state,
        items: [action.payload, ...state.items],
        selectedItem: state.items[0],
      };
    case SET_LIBRARY_SEARCH_CONFIG:
      return {
        ...state,
        librarySearchConfig: action.payload,
      };
    default:
      return state;
  }
}
