
import trackFields from "../../../_module/definition/trackFields";

export default {
  discreteByIsrc: {id: "discreteByIsrc", fields: [trackFields.isrc.id]},
  discreteByTitlePerformer: {id: "discreteByTitlePerformer" , fields: [trackFields.trackTitle.id, trackFields.performer.id]}, 
  discreteByTitlePerformerCatNo: {id: "discreteByTitlePerformerCatNo", fields: [trackFields.trackTitle.id, trackFields.performer.id, trackFields.catNo.id] }, 
}


