import { actionTypes } from "../actions/MlAlbumTrackActions";
import { SortTypes } from "../../util/table-view/TableTypes";
import { defaultResultsPerPage, pagingTotalRelation } from "../Types";
import trackAllFileds from "../../_module/definition/trackAllFileds";
import { albumresultViews } from "_module/definition/MlAlbumSearch";

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  resultView: albumresultViews.single.id,
  album: null,
  postUpdating: false,
  query: {
    order: SortTypes.asc,
    orderBy: "position",
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    qfs: trackAllFileds,
    filters: [],
    albumId: null,
  },
  errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: null,
        items: action.payload.items,
        totalItems: action.payload.total,
        totalRelation: action.payload.totalRelation,
        selectedItem: (action.payload.items && action.payload.items.length > 0) ? action.payload.items[0] : {},
        selectedItems: [],
        postUpdating: action.payload.postUpdating ? true : false,
      };

      case actionTypes.POST_UPDATE:
        return {
          ...state,
          items: action.payload.items,
          postUpdating: false,
        };

    case actionTypes.EMPTY_ITEM:
      return {
        ...state,
        trackEditing: null,
      };


    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };

    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.RESULT_VIEW:
      return {
        ...state,
        resultView: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      var items = state.items.find((r) => r.id === action.payload.id)
        ? state.items.map((i) =>
            i.id === action.payload.id ? action.payload : i
          )
        : [action.payload, ...state.items];

      return {
        ...state,
        items,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };
    case actionTypes.SET_ALBUM:
      return {
        ...state,
        album: action.payload,
      };
    case actionTypes.SET_MESSAGE:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.RESET:
      return initialState;

    default:
      return state;
  }
}
