import React, { Component } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { styles } from "./PlayerRef.css";
import PropTypes from "prop-types";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import { playAudio, pauseAudio, setPopoutFor } from "redux/actions/PlayerActions";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import {
  _mlPlayer,
  _canPreview,
  _canPreviewOptions,
} from "../../../_module/definition/MlPlayer";
import LockIcon from "@material-ui/icons/Lock";
import { _PermittedContent } from "../../../_module/utils/PermittedContent";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { CircularProgress } from "@material-ui/core";
import { BsPlayFill } from "react-icons/bs";
import clsx from "clsx";
import Confirm from "./../../../util/Confirm";

export const PlayableTypes = {
  track: "track",
  playlist: "playlist",
  album: "album",
};

class PlayerRef extends Component {
  constructor(props) {
    super();
    this.state = {
      message: "",
    };
  }

  getIsRestrictedcTag = (item, tags = []) => {
    if (item.cTagList && item.cTagList.length > 0) {
      let res = item.cTagList.find(
        (v) => tags.includes(v.indicator) && v.result
      );
      return res ? true : false;
    } else {
      return false;
    }
  };

  getCanPreviewState = (item, track) => {
    let canPreview = _PermittedContent([_mlPlayer, _canPreview], []);
    switch (canPreview) {
      case _canPreviewOptions.all.id:
        return true;
      case _canPreviewOptions.none.id:
        return false;
      case _canPreviewOptions.noRestricted.id:
        return track?.restricted ||
          item?.restricted ||
          this.getIsRestrictedcTag(item, ["RES"])
          ? false
          : true;
      case _canPreviewOptions.noSS.id:
        return this.getIsRestrictedcTag(item, ["STOPS"]) ? false : true;
      case _canPreviewOptions.noResOrSS.id:
        return track?.restricted ||
          item?.restricted ||
          this.getIsRestrictedcTag(item, ["RES"]) ||
          this.getIsRestrictedcTag(item, ["STOPS"])
          ? false
          : true;
      default:
        return false;
    }
  };

  showMessage = (msg) => {
    this.setState({ message: msg });
  };

  render() {
    const {
      classes,
      popoutFor, 
      setPopoutFor, 
      player: {
        playObject,
        track,
        isPlaying,
        isAssetLoading,
        // playTime,
        // playDuration,
        isAudioError,
        errorMessage,
      },
      addToPlaylist,
      item,
      playableType,
      handlePlayAudio,
      handlePauseAudio,
      ariaLabel,
      isMlAdded,
    } = this.props;

    const { message } = this.state;

    const canPreview = this.getCanPreviewState(item, track);

    return (
      <Translation>
        {(t, { i18n }) => {
          if (addToPlaylist) {
            if (playObject?.id === item?.id) {
              return (
                <>
                  {message && (
                    <Confirm
                      name="isPlayable"
                      classes
                      isOpen={message ? true : false}
                      title="Media Player"
                      content={message}
                      confirm="Ok"
                      onConfirm={() => {
                        this.setState({ message: "" });
                      }}
                    />
                  )}
                  <IconButton
                    aria-label="Play All Tracks"
                    title="Play All Tracks"
                    className={classes.playAllButton}
                    onClick={() => handlePlayAudio(null)}
                  >
                    <PlaylistAddCheckIcon />
                  </IconButton>
                </>
              );
            } else {
              return (
                <>
                  {message && (
                    <Confirm
                      name="isPlayable"
                      classes
                      isOpen={message ? true : false}
                      title="Media Player"
                      content={message}
                      confirm="Ok"
                      onConfirm={() => {
                        this.setState({ message: "" });
                      }}
                    />
                  )}
                  <IconButton
                    aria-label="Play All Tracks"
                    title="Play All Tracks"
                    className={classes.playAllButton}
                    onClick={() => {
                      handlePlayAudio({
                        item,
                        playableType,
                        showMessage: this.showMessage,
                      })
                      if(popoutFor){
                        setPopoutFor(popoutFor);
                      }
                    }}
                  >
                    <BsPlayFill />
                  </IconButton>
                </>
              );
            }
          } else {
            return (
              <>
                {message && (
                  <Confirm
                    name="isPlayable"
                    classes
                    isOpen={message ? true : false}
                    title="Media Player"
                    content={message}
                    confirm="Ok"
                    onConfirm={() => {
                      this.setState({ message: "" });
                    }}
                  />
                )}
                {canPreview && !isMlAdded ? (
                  <>
                    {playObject?.id === item?.id ? (
                      isAssetLoading ? (
                        <IconButton
                          aria-label={
                            ariaLabel ? ariaLabel : t("player.playButton")
                          }
                          title={ariaLabel ? ariaLabel : t("player.playButton")}
                          className={classes.tablePlayNow}
                        >
                          <CircularProgress />
                        </IconButton>
                      ) : isPlaying ? (
                        isAudioError ? (
                          <Badge
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            overlap="circle"
                            aria-label={t("player.error")}
                            title={t("player.error") + ": " + errorMessage}
                            badgeContent={"!"}
                            color="secondary"
                          >
                            <IconButton
                              aria-label={t("player.pauseButton")}
                              title={t("player.pauseButton")}
                              onClick={() => handlePauseAudio()}
                              className={classes.tablePlayNow}
                            >
                              <PauseIcon />
                            </IconButton>
                          </Badge>
                        ) : (
                          <IconButton
                            aria-label={t("player.pauseButton")}
                            className={classes.tablePlayNow}
                            title={t("player.pauseButton")}
                            onClick={() => handlePauseAudio()}
                          >
                            <PauseIcon />
                          </IconButton>
                        )
                      ) : isAudioError ? (
                        <Badge
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          overlap="circle"
                          aria-label={t("player.error")}
                          title={t("player.error") + ": " + errorMessage}
                          badgeContent={"!"}
                          color="secondary"
                        >
                          <IconButton
                            aria-label={
                              ariaLabel ? ariaLabel : t("player.playButton")
                            }
                            title={
                              ariaLabel ? ariaLabel : t("player.playButton")
                            }
                            className={classes.tablePlayNow}
                            onClick={() => handlePlayAudio(null)}
                          >
                            <PlayArrowIcon />
                          </IconButton>
                        </Badge>
                      ) : (
                        <IconButton
                          aria-label={
                            ariaLabel ? ariaLabel : t("player.playButton")
                          }
                          title={ariaLabel ? ariaLabel : t("player.playButton")}
                          className={classes.tablePlayNow}
                          onClick={() => handlePlayAudio(null)}
                        >
                          <PlayArrowIcon />
                        </IconButton>
                      )
                    ) : (
                      <>
                        <IconButton
                          aria-label={
                            ariaLabel ? ariaLabel : t("player.playButton")
                          }
                          title={ariaLabel ? ariaLabel : t("player.playButton")}
                          className={classes.tablePlayNow}
                          onClick={() => {
                            handlePlayAudio({
                              item,
                              playableType,
                              showMessage: this.showMessage,
                            })
                            if(popoutFor){
                              setPopoutFor(popoutFor);
                            }
                          }}
                        >
                          <PlayArrowIcon />
                        </IconButton>
                      </>
                    )}
                  </>
                ) : (
                  <div class={clsx(classes.noPermissionIcon, "notPermitted")}>
                    <LockIcon
                      aria-label="No Permission"
                      title="No Permission"
                      className={classes.noPermission}
                    />
                  </div>
                )}
              </>
            );
          }
        }}
      </Translation>
    );
  }
}

PlayerRef.propTypes = {
  player: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  handlePlayAudio: PropTypes.func.isRequired,
  handlePauseAudio: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePlayAudio: (payload) => dispatch(playAudio(payload)),
    handlePauseAudio: () => dispatch(pauseAudio()),
    setPopoutFor: (payload) => dispatch(setPopoutFor(payload)),
  };
};

const mapStateToProps = (state) => ({
  player: state.player,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlayerRef));
