import { actionTypes } from "../actions/MlLicFormContentActions";
import { SortTypes } from "../../util/table-view/TableTypes";

const initialState = {
  wizard: { ids: [], copyLive: false },
  items: [],
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  noMore: true,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  query: {
    order: SortTypes.desc,
    orderBy: "updatedAt",
    page: 0,
    size: 50,
    q: "",
    filters: [],
  },
  errors: null,
  history:{}, 
  changedFields: [],
  changedTrackFields:[],
  activePreview : 0
};

export default function (state = initialState, action) {
 
  switch (action.type) {
    case actionTypes.OPENWIZARD:
      return {
        ...state,
        wizard: action.payload,
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        selectedItems: [],
        selectedItem: {},
        items: [],
        editing: null,
      };
    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: null,
        editing: action.payload.data,
        items: action.payload.items,
        selectedItems: [],
        selectedItem: (action.payload.items && action.payload.items.length > 0) ? action.payload.items[0] : null,
      };

    case actionTypes.EDITING:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.REFRESH_CF:

      return {
        ...state,
        editing: {
          ...state.editing,
          ...action.payload.data,
            },
        items: action.payload.items,
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        editing: null,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.REFRESH_ITEMS:

  
      return {
        ...state,
        items: action.payload,
        selectedItem : state.selectedItem ? action.payload.find(v=>v.id === state.selectedItem.id) || action.payload[0] ?   action.payload[0]: null :action.payload[0] ?   action.payload[0]: null  
      };
    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
        changedFields:[], 
        changedTrackFields:[], 
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.POST_PPL_ERROR:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.CLEAR_ERRORS:
      return {
        ...state,
        posting: {
          errors: null,
        },
      };

    case actionTypes.LOAD_HISTORY:
      if(action.payload.cfId in state.history){
        state.history[action.payload.cfId][action.payload.trackId] = action.payload.history;
      }
      else{
        state.history[action.payload.cfId] = {};
        state.history[action.payload.cfId][action.payload.trackId] = action.payload.history;
      }
      return {
        ...state
      };
      
    case actionTypes.REMOVE_HISTORY:
      if(action.payload.cfId in state.history){
        if(action.payload.trackId in state.history[action.payload.cfId]){
          delete state.history[action.payload.cfId][action.payload.trackId];
        }
      }
      return {
        ...state
      };

    case actionTypes.REMOVE_HISTORY_ALL:
      if(action.payload.cfId in state.history){
        delete state.history[action.payload.cfId];
      }
      return {
        ...state
      };

    case actionTypes.PREVIEW_TAB:
      return {
        ...state,
        activePreview : action.payload,
      };

    default:
      return state;
  }
}
