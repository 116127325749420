import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { OriginTypes, pagingTotalRelation } from "redux/Types";
import { userPrefType } from "../Types";
import { deepCopyFunction } from "util/CommonFunc";
import albumFields from "_module/definition/albumFields";
import { TerritoryList } from "util/table-view/TableTypes";
module = "/mlTracks/album/";

var tId = null;

export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  // LOADING_MORE: "LOADING_MORE" + module,
  // LOAD_MORE: "LOAD_MORE" + module,
  // LOAD_NOMORE: "LOAD_NOMORE" + module,
  CLEAR: "CLEAR" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  RESULT_VIEW: "RESULT_VIEW"+ module,
  ADV_SEARCH: "ADV_SEARCH"+ module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_MESSAGE: "SET_MESSAGE" + module,
  CLEAR_MESSAGE: "CLEAR_MESSAGE" + module,
  LOAD_QUERY: "LOAD_QUERY" + module,

  CLOUD_OPEN : "CLOUD_OPEN" + module,
  CLOUD_CLOSE : "CLOUD_CLOSE" + module,
  CLOUD_QUERY : "CLOUD_QUERY" + module,
  CLOUD_SEARCH : "CLOUD_SEARCH" + module,
  CLOUD_LOADING: "CLOUD_LOADING" + module,
  CLOUD_LOAD: "CLOUD_LOAD" + module,
  CLOUD_ERROR: "CLOUD_ERROR" + module,
  CLOUD_SET_PERS_FIELD: "CLOUD_SET_PERS_FIELD" + module,
  SET_SUGGESTIONS_LIST: "SET_SUGGESTIONS_LIST" + module,

};

export const cloudOpen = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLOUD_OPEN
  });

};

export const cloudSetPersonalField = (field, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.CLOUD_SET_PERS_FIELD,
    payload: field,
  });

  if (done) done(true);
};

export const cloudClose = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLOUD_CLOSE
  });
};

export const cloudSetQuery = (query) => (dispatch) => {
  dispatch({
    type: actionTypes.CLOUD_QUERY,
    payload: query,
  });

};

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.POST_OK,
    });
  }, 3000);
}

export const cloudSearch = (query, mainQ = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  if (mainQ){
    mainQ.cloudQuery = query;
    delete mainQ.cloudQuery['filters']
  } 

  dispatch({
    type: actionTypes.CLOUD_QUERY,
    payload: query,
  });

  dispatch({
    type: actionTypes.CLOUD_LOADING,
  });

  axios
    .post(`${orgID}/albumcloud/search`, mainQ? mainQ : query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.CLOUD_LOAD,
          payload: res.data.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.CLOUD_ERROR,
        payload: { e: err },
      });
    });
};

export const SetSearchSuggList = (list) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SUGGESTIONS_LIST,
    payload: list,
  });
};

export const updateSearchUserPref = (pref, message, done = null) => (
  dispatch
) => {
  const {
    user: { id },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(
      `${id}/userpref/update`,
      { type: userPrefType.ALBUMSEARCHPREF, pref: pref },
      {
        headers: { tid: tId },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const setResultView = (view) => (dispatch) => {
  dispatch({
    type: actionTypes.RESULT_VIEW,
    payload: view,
  });
};

export const setAdvSearch = (advsearch) => (dispatch) => {
  dispatch({
    type: actionTypes.ADV_SEARCH,
    payload: advsearch,
  });
};

export const setQuery = (query, done = null) => (dispatch) => {
  //console.log("setQuery", query);
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const showMessage = (message) => (dispatch) => {
  if (message) {
    dispatch({
      type: actionTypes.SET_MESSAGE,
      payload: message,
    });
  } else {
    dispatch({
      type: actionTypes.CLEAR_MESSAGE,
    });
  }
};

export const clear = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR,
  });
};

export const selectItems = (items) => (dispatch) => {
  //console.log("selectItems", items);
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const search = (query, isSandBox = false) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  tId = uuidv4();

  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });
  
  if (
    query ||
    (query && query.q) ||
    (query && query.origin !== OriginTypes.all) ||
    (query && query.filters && query.filters.length > 0) ||
    (query && query.all && query.all.q) ||
    (query && query.tagAll && query.All.q)
  ) {
    const options = {
      headers: {
        tid: tId,
      },
    };
    dispatch({
      type: actionTypes.LOADING,
    });


    axios
      .post(isSandBox ? "/albumserachsandbox" : "/albumserach", query, options)
      .then((res) => {
        if (res.data.tid === tId) {
          dispatch({
            type:actionTypes.LOAD,
            payload: res.data.data,
            total: res.data.total,
            totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });
      });
  } else {
    dispatch({
      type: actionTypes.CLEAR,
      payload: [],
    });
  }
};
