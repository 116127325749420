import { T_Major, T_Branch } from "_module/definition/ModulePropTypes";
import modules from "_module/definition/Modules";
import { PT_System } from "../definition/ModulePermitTypes";
import {
  T_LeafOptionsMulti,
  T_LeafOptionsMultiOrdered,
  T_LeafOptionsSingle,
} from "../definition/ModulePropTypes";
// export const _newModuleInstance = (module) => {
//   //console.log(module);
//   if (module.type === T_Major || module.type === T_Branch) {
//     let lreturn = { id: module.id };
//     module.modules.map((v) => {
//       lreturn[v.id] = _newModuleInstance(v);
//     });
//     return lreturn;
//   } else if (
//     module.type == T_LeafOptionsMulti ||
//     module.type == T_LeafOptionsMultiOrdered
//   ) {
//     return { id: module.id, v: module.default ? module.default : [] };
//   } else if (module.type == T_LeafOptionsSingle) {
//     return { id: module.id, v: module.default ? module.default : "" };
//   } else return { id: module.id, v: module.default ? module.default : false };
// };

export const _getModuleDef = (id) => {
  return modules.find((v) => v.id === id);
};

export const _expectPermission = (
  sysPermit,
  orgPermit,
  orgID,
  path,
  expects
) => {
  let v = null;
  let inPermission = sysPermit;
  sysPermit = sysPermit ? sysPermit : [];
  orgPermit = orgPermit ? orgPermit : {};

  if (Array.isArray(path)) {
    let i = 0;
    while (i < path.length) {
      let module = path[i];

      if (i === 0 || module.type === T_Major) {
        inPermission =
          module.permitType === PT_System
            ? sysPermit
            : orgPermit[orgID]
            ? orgPermit[orgID]
            : [];
        inPermission = inPermission.find((v) => v.id === module.id);
      } else if (module.type === T_Branch) {
        inPermission =
          inPermission && inPermission[module.id]
            ? inPermission[module.id]
            : null;
      } else
        inPermission =
          inPermission && inPermission[module.id]
            ? inPermission[module.id].v || null
            : null;

      v = inPermission;
      i++;
      if (v === null) break;
    }
  } else {
    inPermission =
      path.permitType === PT_System ? sysPermit : orgPermit[orgID] || [];
    v = inPermission.find((v) => v.id === path.id);
  }

  if (expects && expects == []) return v ? v : [];
  else if (expects) return v ? v : null;
  else return v ? true : false;
};
