import Axios from "axios";
import store from "redux/Store";

export default (type) =>
new Promise(function (resolve, reject) {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  Axios
  .get(`${orgID}/settings/${type}`)
  .then((res) => {
      resolve(res.data)
  })
  .catch((err) => {
    resolve([]);
    });
});