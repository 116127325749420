import { actionTypes } from "./ContentAlertActions";
import { defaultResultsPerPage } from "../../../../redux/Types";
import { SortTypes } from "../../../../util/table-view/TableTypes";
import { ContentAlertPreview } from "../Const";

const initialState = {
  items: [],
  totalItems: 0,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  noMore: true,
  query: {
    order: SortTypes.desc,
    orderBy: "lastEdited",
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    filters: [],
  },
  errors: null,
  activePreview: ContentAlertPreview.METADATA,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        selectedItem: action.payload[0] || {},
        selectedItems: [],
        noMore: false,
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        noMore: true,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.REFRESH_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POSTING_ERROR:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.LINK_CONTENT_REQUEST:
      return {
        ...state,
        linkContentMsg: action.payload,
      };

    case actionTypes.PREVIEW_TAB:
      return {
        ...state,
        activePreview: action.payload,
      };

    default:
      return state;
  }
}
