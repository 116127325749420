export const __cfStatus = {
  inprogress: { id: "inprogress", label: "Inprogress", color: "Yellow" },
  completed: { id: "completed", label: "Completed", color: "green" },
  archived: { id: "archived", label: "Archived", color: "red" },
};

export const __trackStatus = {
  inprogress: { id: "inprogress", name: "Inprogress", color: "Yellow" },
  norule: { id: "norule", name: "No Rule", color: "gray" },
  clearMe: { id: "0", name: "Clear Me", color: "" },
};
