import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
//import { ValidateRole } from "../../util/validations/MlAdminRoleActionsValidation";
import { ValidateContentRequest } from "../../../util/validations/MessageComposeValidation";
module = "/messages";
export const actionTypes = {

  NEW_COUNT: "NEW_COUNT" + module,
  NEW_UNREAD_COUNT: "NEW_UNREAD_COUNT" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  EDIT_ITEMS_SAVECB : "EDIT_ITEMS_SAVECB" + module,
  CLOSE_MESSAGECB : "CLOSE_MESSAGECB" + module,
  UPDATE_CONTENTREQ_STATUS : "UPDATE_CONTENTREQ_STATUS" + module,

  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,


  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  SETTING_AS_READ: "SETTING_AS_READ" + module,
  SETTING_AS_READ_COMPLETE: "SETTING_AS_READ_COMPLETE" + module,
  SETTING_AS_READ_ERROR: "SETTING_AS_READ_ERROR" + module,
  // LOADING_MORE: "LOADING_MORE" + module,
  // LOAD_MORE: "LOAD_MORE" + module,
  // LOAD_NOMORE: "LOAD_NOMORE" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,

  USE_POPUP: "USE_POPUP" + module, 
};

export const setUsePopup = (usePopup, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.USE_POPUP,
    payload: usePopup,
  });
};

export const setNewCount = (count) => (dispatch) => {
  dispatch({
    type: actionTypes.NEW_COUNT,
    payload: count,
  });
};

export const setUnreadCount = (count) => (dispatch) => {
  dispatch({
    type: actionTypes.NEW_UNREAD_COUNT,
    payload: count,
  });
};

export const editMessage = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const setEditMessageCB = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEMS_SAVECB,
    payload: item,
  });
};

export const updateContentReqStatus = (action, message, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/messages/${action}`, message, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
          if (done) done(null, err);
    });
  
};


export const closeMessageCB = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE_MESSAGECB,
    payload: item,
  });
};

export const validateCR = (data, done = null) => (dispatch) => {
  if (data) {
    let errors = ValidateContentRequest(data);
    if (done) done(errors);
  }
};

export const postMessage = (action, message, item, done = null) => (dispatch) => {
  

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/messages/${action}`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        // payload: { e: err },
        payload: err.response?.data || {
          e: err.message
            ? err.message
            : "Error in creating or updating. please retry",
        },
      });

      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};
export const search = (query, searchOnClose = false) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  
  if(!searchOnClose){
    dispatch({
      type: actionTypes.LOADING,
    });
  }

  axios
    .post(`${orgID}/messages/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          unread: res.data.totalUnreadCount,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};



export const setMessageAsRead = (done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  dispatch({
    type: actionTypes.SETTING_AS_READ,
  });

  axios
    .post(`${orgID}/messages/setasread`, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.SETTING_AS_READ_COMPLETE,
        payload: res,
      });
      if(done) done(res,null)
      
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.SETTING_AS_READ_ERROR,
        payload: { e: err },
      });
      if(done) done(null,err)
    });
};
