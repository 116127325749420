import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { ValidateReport } from "../../util/validations/SmReportsActionsValidation";
import { logUserActivity, logUserActivityA, pagingTotalRelation} from "../Types";

module = "/reports/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POST_OK" + module,
  SAVE_OK: "SAVE_OK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_ERROR_LOADING_USERS: "SET_ERROR_LOADING_USERS" + module,
  SET_USERS: "SET_USERS" + module,
  SET_LOG_ACTIVITIES: "SET_LOG_ACTIVITIES" + module,
  SET_ERROR_LOADING_ACTIVITIES: "SET_ERROR_LOADING_ACTIVITIES" + module,
  SET_WORKSPACES: "SET_WORKSPACES" + module,
  SET_ERROR_LOADING_WORKSPACES: "SET_ERROR_LOADING_WORKSPACES" + module,
};

export const editItem = (item) => (dispatch) => {
  
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const postItem = (action, message, items, done = null) => (dispatch) => {
  
  if (action == 'save') {
    let errors = ValidateReport(items.report);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/reports/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: (action == 'save') ? actionTypes.SAVE_OK : actionTypes.POST_OK,
        payload: res.data
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        //payload: { e: err },
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. Please retry") },
      });

      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};
export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });

  axios
    .post(`${orgID}/reports/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};


export const getUsers = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/reports/getallusers`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.SET_USERS,
        users: response.data,
      });
      
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SET_ERROR_LOADING_USERS,
        users: [],
        errors: error
      });
    });
};


export const getActivities = (query) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOG_ACTIVITIES,
    activities: logUserActivityA.sort((a,b) => (a.label > b.label) ? 1 : -1),
  });

};

export const getActivitiesForReports = (query) => (dispatch) => {
  let activityList = logUserActivityA.sort((a,b) => (a.label > b.label) ? 1 : -1);

  const moveToFront = [logUserActivity.DOWNLOAD_LOW_RES_RESTRICTED, logUserActivity.DOWNLOAD_LOW_RES,
    logUserActivity.DOWNLOAD_HI_RES_RESTRICTED, logUserActivity.DOWNLOAD_HI_RES,
    logUserActivity.STREAM_TRACK_RESTRICTED, logUserActivity.STREAM_TRACK, logUserActivity.SYSTEM_LOGIN
  ];

  activityList = activityList.filter(function (currentAct) {
    return !(moveToFront.find(o => o == currentAct.id));
  });

  moveToFront.forEach(function (currentAct, index, array) {
    activityList.unshift({ id: currentAct, label: (logUserActivityA.find(o => o.id === currentAct))?.label })
  });

  dispatch({
    type: actionTypes.SET_LOG_ACTIVITIES,
    activities: activityList,
  });
};


export const getWorkspaces = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/reports/getWorkspacesByOrg`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.SET_WORKSPACES,
        workspaces: response.data,
      });
      
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SET_ERROR_LOADING_WORKSPACES,
        workspaces: [],
        errors: error
      });
    });
};