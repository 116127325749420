import { actionTypes } from "../actions/MlPromoPlaylistActions";
import { SortTypes } from "../../util/table-view/TableTypes";
import { FilterOperatorTypes } from "../../util/table-view/TableTypes";
import {
  resultViews,
  searchProfiles,
  timeSorts,
  viewerTypes,
} from "../../_module/definition/MlLibraries";
import { defaultResultsPerPage, pagingTotalRelation,  } from "../Types";

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  noMore: true,
  plrecent: { busy: false, message: "", errors: null },
  orgusers: null,
  plrecentList: null,
  query: {
    order: SortTypes.desc,
    orderBy: [
      {orderBy: "_score", order: "desc"},
      {orderBy: "priority", order: "asc"},
      // { orderBy: "updatedAt", order: "desc" },
    ],
    page: 0,
    size: defaultResultsPerPage,
    category: 0,
    resultView: resultViews.single.id,
    viewerType: viewerTypes.cardView.id,
    searchProfile: searchProfiles.mainSearch.id,
    timeSort: timeSorts.defaultOrder.id,
    q: "",
    tagAll: { q: "", f: ["tags"] },
    filters: [
      { operator: FilterOperatorTypes.equals, value: ["1"], field: "status" },
      { operator: FilterOperatorTypes.equals, value: ["1"], field: "type" },
    ],
  },
  errors: null,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        selectedItems: [],
        noMore: false,
      };


    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        noMore: true,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      var items = state.items.find((r) => r.id === action.payload.id)
        ? state.items.map((i) =>
            i.id === action.payload.id ? action.payload : i
          )
        : [action.payload, ...state.items];

      return {
        ...state,
        items,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.PL_UPDATING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.PL_UPDATE_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.PL_UPDATE_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.SET_PLRECENT_Q:
      return {
        ...state,
        plrecentQuery: action.payload,
      };

    case actionTypes.LOADING_EDITABLE_PL:
      return {
        ...state,
        posting: {
          busy: true,
          message: "",
          errors: null,
        },
      };

    case actionTypes.LOAD_EDITABLE_PL:
      return {
        ...state,
        plrecentList: action.payload,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.RESULT_VIEW:
      return {
        ...state,
        query: { ...state.query, resultView: action.payload },
      };

    case actionTypes.SEARCH_PROFILE:
      return {
        ...state,
        query: { ...state.query, searchProfile: action.payload },
      };

    case actionTypes.SET_CATEGORY:
      return {
        ...state,
        query: { ...state.query, category: action.payload },
      };

    case actionTypes.SET_TIME_SORT:
      return {
        ...state,
        query: { ...state.query, timeSort: action.payload , orderBy: ((action.payload === timeSorts.defaultOrder.id) ? timeSorts.defaultOrder.id : "updatedAt"), order: ((action.payload === timeSorts.newestFirst.id) ? SortTypes.desc : SortTypes.asc) },
      };

      case actionTypes.SET_VIEW_TYPE:
        return {
          ...state,
          query: { ...state.query, viewerType: action.payload },
        };
  
      
    default:
      return state;
  }
}
