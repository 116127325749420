import { actionTypes } from "../actions/MlUploadSessionAction";
import { SortTypes } from "../../util/table-view/TableTypes";
import { defaultResultsPerPage, pagingTotalRelation,  } from "../Types";

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  noMore: true,
  playlist: null,
  sessionId: null,
  sessionCreated: false,
  query: {
    order: SortTypes.desc,
    orderBy: "date_last_edited",
    page: 0,
    size: defaultResultsPerPage,
    q: "",   
    filters: []    
  },
  errors: null,
  uploadQueue: [],

};

export default function (state = initialState, action) {
    switch (action.type) {      

      case actionTypes.LOADING:
        return {
          ...state,
          loading: true,
          loadingMore: false,
          errors: null,
          selectedItems: [],
          items: [],

        };
  
      case actionTypes.LOAD:     
        return {
          ...state,
          loading: false,
          loadingMore: false,
          errors: null,
          items: action.payload,
          totalItems: action.total,
          totalRelation: action.totalRelation,
          selectedItem: action.payload[0] || {},
          selectedItems: [],
          noMore: true, //load more not supported by playlist content
        };
  
      case actionTypes.ERROR:
        return {
          ...state,
          loading: false,
          loadingMore: false,
          errors: action.payload,
          items: [],
          totalItems: 0,
          totalRelation: pagingTotalRelation.EQUALS,
          noMore: true,

        };
  
      case actionTypes.SELECT_ITEMS:
        return {
          ...state,
          selectedItems: action.payload,
        };

        case actionTypes.UPDATE_QUEUE:
          return {
            ...state,
            uploadQueue: action.payload,
          };
  
        
      case actionTypes.QUERY:
        return {
          ...state,
          query: action.payload,
        };
      case actionTypes.FOCUS_ITEM:
        return {
          ...state,
          selectedItem: action.payload,
          playlist: action.payload,
          sessionId: action.payload.id

        };
  
      case actionTypes.EDIT_ITEM:
        return {
          ...state,
          editing: action.payload,

        };
  
      case actionTypes.POSTING:
        return {
          ...state,
          posting: {
            busy: true,
            message: action.payload,
            errors: null,
          },

        };
  
        case actionTypes.POST_OK:         
          return {
            ...state,
            posting: {
              busy: false,
              message: "",
              errors: null
            },
            sessionId: action.sessionId,
            sessionCreated: true
          };
  
      case actionTypes.POST_ERROR:
        return {
          ...state,
          posting: {
            busy: false,
            message: "",
            errors: action.payload,
          },

        };
      case actionTypes.SET_PLAYLIST: {
        //console.log(action);
        return {
          ...state,
          playlist: action.payload,
        };
        
      }
      default:
        return state;
    }
  }