import {
  T_LeafBool,
  T_LeafOptionsMultiOrdered,
  T_Major,
} from "./ModulePropTypes";
import { PT_Setting, PT_Org } from "./ModulePermitTypes";
import trackFields from "./trackFields";

export const _settingMetadataConditional = {
  id: "sm1",
  permitType: PT_Org,
  type: T_LeafBool,
  default: true,
};
export const _settingMetadataFieldsDefault = {
  id: "sm2",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(trackFields).map((key) => {
      if (!trackFields[key].sys) nonesys[key] = trackFields[key];
    });
    return nonesys;
  },
  default: [
    trackFields.id.id,
    trackFields.trackTitle.id,
    trackFields.libName.id,
    trackFields.prodName.id,
    trackFields.catNo.id,

    trackFields.musicOrigin.id,
    trackFields.isrc.id,
    trackFields.iswc.id,
    trackFields.prs.id,
    trackFields.alternativeTitle.id,
    trackFields.trackVersionTitle.id,
    trackFields.duration.id,
    trackFields.position.id,
    trackFields.subIndex.id,

    trackFields.prodArtist.id,
    trackFields.performer.id,
    trackFields.composer.id,
    trackFields.arranger.id,
    trackFields.publisher.id,
    trackFields.translator.id,
    trackFields.recordLabel.id,
    trackFields.lyricist.id,

    trackFields.prodYear.id,
    trackFields.prodRelease.id,
    trackFields.prodNumberOfDiscs.id,
    trackFields.prodDiscNr.id,

    trackFields.genres.id,
    trackFields.styles.id,
    trackFields.moods.id,
    trackFields.instruments.id,
    trackFields.bpm.id,
    trackFields.tempo.id,
    trackFields.keywords.id,
    trackFields.trackNotes.id,
    trackFields.prodNotes.id,

    trackFields.arid.id,
    trackFields.wsId.id,
    trackFields.wsName.id,
    trackFields.dateCreated.id,
    trackFields.dateLastEdited.id,
  ],
};

export const _settingMetadataFieldsLibrary = {
  id: "sm3",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(trackFields).map((key) => {
      if (!trackFields[key].sys) nonesys[key] = trackFields[key];
    });
    return nonesys;
  },
  default: [
    trackFields.musicOrigin.id,
    trackFields.id.id,
    trackFields.trackTitle.id,
    trackFields.prodName.id,
    trackFields.catNo.id,

    trackFields.libName.id,
    trackFields.composer.id,
    trackFields.publisher.id,
    trackFields.prs.id,

    trackFields.genres.id,
    trackFields.styles.id,
    trackFields.moods.id,
    trackFields.instruments.id,
    trackFields.bpm.id,
    trackFields.tempo.id,
    trackFields.keywords.id,
    trackFields.trackNotes.id,
    trackFields.prodNotes.id,
  ],
};

export const _settingMetadataFieldsCommercial = {
  id: "sm4",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(trackFields).map((key) => {
      if (!trackFields[key].sys) nonesys[key] = trackFields[key];
    });
    return nonesys;
  },
  default: [
    trackFields.musicOrigin.id,
    trackFields.id.id,
    trackFields.trackTitle.id,
    trackFields.prodArtist.id,
    trackFields.performer.id,
    trackFields.isrc.id,
    trackFields.recordLabel.id,
    trackFields.prodName.id,
    trackFields.catNo.id,
    trackFields.publisher.id,
    trackFields.composer.id,
    trackFields.prs.id,
    trackFields.prodYear.id,

    trackFields.trackNotes.id,
    trackFields.prodNotes.id,
  ],
};

export const _settingMetadataFieldsCommissioned = {
  id: "sm5",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(trackFields).map((key) => {
      if (!trackFields[key].sys) nonesys[key] = trackFields[key];
    });
    return nonesys;
  },
  default: [
    trackFields.musicOrigin.id,
    trackFields.id.id,
    trackFields.trackTitle.id,
    trackFields.prodArtist.id,
    trackFields.composer.id,
    trackFields.performer.id,
    trackFields.prs.id,

    trackFields.genres.id,
    trackFields.styles.id,
    trackFields.moods.id,
    trackFields.instruments.id,
    trackFields.bpm.id,
    trackFields.tempo.id,
    trackFields.keywords.id,
    trackFields.trackNotes.id,
    trackFields.prodNotes.id,
  ],
};

export const _settingMetadata = {
  id: "_settingMetadata",
  permitType: PT_Setting,
  type: T_Major,
  modules: [
    _settingMetadataConditional,
    _settingMetadataFieldsDefault,
    _settingMetadataFieldsCommercial,
    _settingMetadataFieldsCommissioned,
    _settingMetadataFieldsLibrary,
  ],
};
