import { textLengths } from "../../../redux/Types";


export const validateCreateCtag = (data) => {
  let errors = {};
  if (data) {
    if (!data.name && !data.name?.length) {
      errors.name = "Name should not be empty.";
    }
    if (data.name?.length && data.name?.length > textLengths.TEXT) {
      errors.name = "Name should be less than 256 characters.";
    }

    if (data.display_indicator) {
      if (!data.indicator || !data.indicator?.length) {
        errors.indicator = "Indicator should not be empty.";
      }
      if (data.indicator?.length && data.indicator?.length > textLengths.INDICATOR) {
        errors.indicator = "Indicator should be less than 7 characters.";
      }
    }

    if (data.description?.length && data.description?.length > textLengths.TEXT) {
      errors.description = "Description should be less than 256 characters.";
    }


    if (!data.type?.length) {
      errors.type = " Type should not be empty.";
    }



  }
  if (Object.entries(errors).length === 0) {
    return null;
  } else {
    return errors;
  }
};

export const validateCreateRule = (data) => {

  let errors = {};
  if (data) {
    if (!data.c_tag_id && data.c_tag_id <= 0) {
      errors.c_tag_id = " Ctag id should not be empty.";
    }

    if (!data.name && data.name?.length <= 0) {
      errors.name = " Ctag name should not be empty.";
    }
    if (data.name?.length && data.name?.length > textLengths.TEXT) {
      errors.name = "Ctag name should be less than 256 characters.";
    }

    if (!data.status && data.status?.length <= 0) {
      errors.status = " Ctag status should not be empty.";
    }

    if (data.notes?.length && data.notes?.length > textLengths.TEXT) {
      errors.notes = "Ctag notes should be less than 256 characters.";
    }

    if (data.rules?.length && data.rules?.length <= textLengths.SMALL) {
      errors.rules = "Ctag rules should not be empty.";
    }
    if (data.rules?.length) {
      var ctagRule = JSON.parse(data.rules);
      ctagRule.forEach(element => {
        if (element.defaultItem && !element.defaultItem?.value) {
          errors.values = "Ctag rule value should not be empty.";
        }
        if (element.defaultItem && element.defaultItem?.value?.length > textLengths.TEXT) {
          errors.values = "Ctag rule value should be less than 256 characters.";
        }

        if (element.and && !element.and?.value) {
          errors.values = "Ctag rule value should not be empty.";
        }
        if (element.and && element.and?.value?.length > textLengths.TEXT) {
          errors.values = "Ctag rule value should be less than 256 characters.";
        }

        if (element.or) {
          element.or.map((orValue) => {
            if (!orValue.value) {
              errors.values = "Ctag rule value should not be empty.";
            }
            if (orValue.value && orValue.value?.length > textLengths.TEXT) {
              errors.values = "Ctag rule value should be less than 256 characters.";
            }

          })
        }
      });
      // "[{"defaultItem":{"property":"isrc","condition":1,"value":null,"innerProperty":null}}]"
    }
  }

  if (Object.entries(errors).length === 0) {
    return null;
  } else {
    return errors;
  }
};
