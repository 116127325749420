import { textLengths } from "../../redux/Types";
import ParamObjectTypes from "../../_clearance/definition/ParamObjectTypes";

export const ValidateParameters = (paramData) => {
    let errors = {}

    if (paramData) {
        if (!paramData.label) {
            errors.label = "Parameter name should not be empty";
        } else if (paramData.label && paramData.label.length > textLengths.TEXT) {
            errors.label = "Sorry, the name you entered is too long. Please try a shorter name";
        }

        switch (paramData.parent) {
            case ParamObjectTypes.POT_HeaderQuestion:
            case ParamObjectTypes.POT_TrackQuestion:
                if (!paramData.question) {
                    errors.question = "Should not be empty";
                } else if (paramData.question && paramData.question.length > textLengths.TEXT) {
                    errors.question = "Sorry, the question you entered is too long. Please try a shorter question";
                }
                break;

            case ParamObjectTypes.POT_TrackAuto:
                if (!paramData.field) {
                    errors.field = "Should not be empty";
                }
                break;
            case ParamObjectTypes.POT_TrackCTag:
                if (!paramData.ctag) {
                    errors.ctag = "Should not be empty";
                }
                break;
            case ParamObjectTypes.POT_Script:

                break;
            default:
                break;
        }
    }



    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}