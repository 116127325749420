import React from "react";
export default (row, field) => {
var view = ((row.highlights && row.highlights[field]) ? row.highlights[field] : row[field]);

if (Array.isArray(view)){
  view = view.join(", ");
}

if (view && typeof view === 'string'){
    view = view.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/⌌/g, "<span style='background-color: #ffeeb9;color:black;'>").replace(/⌍/g, "</span>")
}

return <span dangerouslySetInnerHTML={{__html: view}}></span>
};