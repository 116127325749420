import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import trackFields from "../../_module/definition/trackFields";
import { FilterOperatorTypes } from "./../../util/table-view/TableTypes";

var module = "/playlistcontent/";
var trackModule = "/mlTracks/library/";

export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  LOAD_ITEMS: "LOAD_ITEMS" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_PLAYLIST: "SET_PLAYLIST" + module,
  FILTER_ACTIVE_TRACKS: "FILTER_ACTIVE_TRACKS" + module,
  SET_MESSAGE: "SET_MESSAGE" + module,
  CLEAR_MESSAGE: "CLEAR_MESSAGE" + module,
  RESET: "RESET" + module,
  POST_UPDATE: "POST_UPDATE" + module,
  STOP_MESSAGE: "STOP_MESSAGE" + module,
  SHOW_CTAG_RULE: "SHOW_CTAG_RULE" + trackModule,
  GET_CTAG_RULE: "GET_CTAG_RULE" + trackModule,
  RETRIEVING_CTAG_RULE: "RETRIEVING_CTAG_RULE" + trackModule,
  EMPTY_CTAG_RULE: "EMPTY_CTAG_RULE" + trackModule,
  PL_UPDATING: "PL_UPDATING" + module,
  PL_UPDATE_OK: "PL_UPDATE_OK" + module,
  PL_UPDATE_ERROR: "PL_UPDATE_ERROR" + module,

};

export const showMessage = (message) => (dispatch) => {
  if (message) {
    dispatch({
      type: actionTypes.SET_MESSAGE,
      payload: message,
    });
  } else {
    dispatch({
      type: actionTypes.CLEAR_MESSAGE,
    });
  }
};

export const filterActiveTracks = (playlist) => (dispatch) => {
  // console.log("editItem", item);
  dispatch({
    type: actionTypes.FILTER_ACTIVE_TRACKS,
    payload: playlist,
  });
};

export const editItem = (item) => (dispatch) => {
  // console.log("editItem", item);
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.STOP_MESSAGE,
    });
  }, 3000);
}

export const setPlaylist = (id) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.RESET,
  });

  axios
    .post(`${orgID}/playlist/search`, {
      order: "desc",
      orderBy: "updatedAt",
      filters: [{ operator: "equals", value: [id], field: "id" }],
      page: 0,
      size: 1,
    })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_PLAYLIST,
        payload:
          res?.data?.results && res.data.results.length > 0
            ? res.data.results[0]
            : null,
      });
    });
};

export const postItem =
  (action, message, items, done = null) =>
  (dispatch) => {
    // console.log("post", action, message, items);
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    const tId = uuidv4();

    dispatch({
      type: actionTypes.POSTING,
      payload: message,
    });

    axios
      .post(`${orgID}/playlist/${action}`, items, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.POST_OK,
          payload: res.data,
        });

        if (done) done(res.data, null);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: { e: err },
        });

        if (done) done(null, err);
      });
  };

export const setQuery =
  (query, done = null) =>
  (dispatch) => {
    // console.log("setQuery", query);
    dispatch({
      type: actionTypes.QUERY,
      payload: query,
    });

    if (done) done();
  };

export const reorderItems = (sourceIndex, destIndex) => (dispatch) => {
  const {
    mlplaylistcontent: { items, playlist },
  } = store.getState();
  var result = items;

  let isUpward = sourceIndex > destIndex;
  let sourceId = result[sourceIndex]?.id;
  let destId = result[destIndex]?.id;

  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destIndex, 0, removed);

  dispatch({
    type: actionTypes.LOAD_ITEMS,
    payload: result,
  });

  shiftPlaylistTrack(playlist.id, isUpward, sourceId, destId).then((result) => {
    if (result) {
      setPlaylistPositions(playlist.id, dispatch);
      dispatch({
        type: actionTypes.SET_PLAYLIST,
        payload: result,
      });
    }
  });
};

var messageShowTimer;

export const setPlaylistTracks = (action, message, object, done = null) => (
  dispatch
) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.PL_UPDATING,
    payload: message,
  });

  axios
    .post(`${orgID}/playlist/${action}`, object, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.PL_UPDATE_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      if (done) done(null, err);
      clearTimeout(messageShowTimer);
      dispatch({
        type: actionTypes.PL_UPDATING,
        payload: "Access denied",
      });
      messageShowTimer = setTimeout(
        () => dispatch({
          type: actionTypes.PL_UPDATE_ERROR,
          payload: { e: err },
        }),
        6000
      );
    });
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });


  // Get Rule List of CTAGS
  const {
    // mltracklibrary: {
    //   ctagRuleList
    // },
    user: {
      credentials: { orgID },
    },
    mlplaylistcontent:{
      selectedItem
    }
  } = store.getState();
    if (selectedItem && selectedItem.cTagList && selectedItem.cTagList.length > 0) {
      var cTagIds = [];
      selectedItem.cTagList.forEach((item) => {
        cTagIds.push(item.ctagId)
      })
      dispatch({
        type: actionTypes.RETRIEVING_CTAG_RULE,
      });

      axios
        .post(`${orgID}/mladmin/ctags/getCtagRules`, { trackId: selectedItem.id, cTagIds: cTagIds })
        .then((res) => {
          dispatch({
            type: actionTypes.GET_CTAG_RULE,
            payload: res.data,
            tagtype: selectedItem.cTagList
          });
          dispatch({
            type: actionTypes.SHOW_CTAG_RULE,
            payload: true,
          });
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.POST_ERROR,
            payload: { e: err },
          });
        });
    }
    else
    {
      dispatch({
        type: actionTypes.GET_CTAG_RULE,
        payload: [],
        tagtype: null
      });
    }
};

export const shiftPlaylistTrack = (id, isUpward, sourceId, destId) =>
  new Promise(function (resolve, reject) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    axios
      .post(`${orgID}/playlist/trackshift`, {
        plid: id,
        isUpward,
        sourceId,
        destId,
      })
      .then((res) => {
        resolve(res?.data?.data && res.data.data.length > 0 ? res.data.data[0] : null);
      })
      .catch((err) => {
        resolve(null);
      });
  });

  
var setPlaylistPositionsTId = null;

export const setPlaylistPositions = (id, dispatch) => {
    const {
      user: {
        credentials: { orgID },
      },
      mlplaylistcontent: { items },
    } = store.getState();

    setPlaylistPositionsTId = uuidv4();

    axios
      .post(
        `${orgID}/playlist/search`,
        {
          filters: [
            {
              operator: FilterOperatorTypes.equals,
              value: [id],
              field: trackFields.id.id,
            },
          ],
        },
        {
          headers: { tid: setPlaylistPositionsTId, orgid: orgID },
        }
      )
      .then((res) => {
        if (res.data.tid === setPlaylistPositionsTId && res?.data?.results && res?.data?.results[0] && res?.data?.results[0].items) {
          const plItems = res.data.results[0].items;

          var result = items;
          for (const item of result){
               item.pl_prop_position = plItems.find(r=> r.id === item.id)?.position ?? null;
          }

          dispatch({
            type: actionTypes.LOAD_ITEMS,
            payload: result,
          });
        }
      })
      .catch((err) => {
      });
  };


var getPlaylistTId = null;

export const getPlaylist = (id) =>
  new Promise(function (resolve, reject) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    getPlaylistTId = uuidv4();

    axios
      .post(
        `${orgID}/playlist/search`,
        {
          filters: [
            {
              operator: FilterOperatorTypes.equals,
              value: [id],
              field: trackFields.id.id,
            },
          ],
        },
        {
          headers: { tid: getPlaylistTId, orgid: orgID },
        }
      )
      .then((res) => {
        //if (res.data.tid === getPlaylistTId) {
        resolve(res?.data?.results ? res.data.results : []);
        //}
      })
      .catch((err) => {
        resolve([]);
      });
  });

var getPlaylistContentTId = null;

export const getPlaylistContent = (id) =>
  new Promise(function (resolve, reject) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    getPlaylistContentTId = uuidv4();

    axios
      .post(
        `${orgID}/plcontent/search`,
        {
          plid: id,
          order: "desc",
          orderBy: "updatedAt",
          page: 0,
          size: 10000,
        },
        {
          headers: { tid: getPlaylistContentTId, orgid: orgID },
        }
      )
      .then((res) => {
        resolve(res?.data?.results ? res.data.results : []);
      })
      .catch((err) => {
        resolve([]);
      });
  });

var searchTransId = null;

export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  searchTransId = uuidv4();
  const options = {
    headers: {
      tid: searchTransId,
    },
  };

  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/plcontent/search`, query, options)
    .then((res) => {
      if (res.data.tid === searchTransId) {
        if (res.data?.results.length > 0) {
          axios
            .post(
              `${orgID}/ctag/CheckExplicitCtag`,
              {
                trackIdList: res.data.results.map((t) => t.id),
              },
              options
            )
            .then((post) => {
              if (post.data.tid === searchTransId) {
                const {
                  mlplaylistcontent: { items },
                } = store.getState();

                for (var item of items) {
                  let trackId = item.id;
                  item.cTagList = post.data.data.find(
                    (opt) => opt.trackId === trackId
                  )?.cTagList;
                }
                dispatch({
                  type: actionTypes.POST_UPDATE,
                  payload: {
                    items: items,
                  },
                });
              }
            });

          dispatch({
            type: actionTypes.LOAD,
            payload: {
              items: res.data.results,
              total: res.data.total,
              postUpdating: true,
              totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
            },
          });
        } else {
          dispatch({
            type: actionTypes.LOAD,
            payload: {
              items: res.data.results,
              total: res.data.total,
              postUpdating: false,
              totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
            },
          });
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};
