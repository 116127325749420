import { textLengths } from "../../redux/Types";

export const ValidateSetting = (settingData) => {
    let errors = {}

    if (settingData) {
        if (!settingData.name) {
            errors.name = "Should not be empty";
        } else if (settingData.name && settingData.name.length > textLengths.TEXT) {
            errors.name = "Sorry, the name you entered is too long. Please try a shorter name";
        }
    }

    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}