import {
  T_LeafBool,
  T_LeafOptionsMultiOrdered,
  T_Major,
} from "./ModulePropTypes";
import { PT_Setting } from "./ModulePermitTypes";
import trackfields from "./trackFields";
import trackOrigins from "./trackOrigins";
import trackAllFields from "./trackAllFileds";

export const _settingSearchShowGlobalQ = {
  id: "ss0",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: true,
};

export const _settingSearchShowAllFields = {
  id: "ss1",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: true,
};

export const _settingSearchAllFields = {
  id: "ss11",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonetagtype = {};
    Object.keys(trackfields).map((key) => {
      if (!trackfields[key].sys)
        nonetagtype[key] = trackfields[key];
    });
    return nonetagtype;
  },
  // default: [
  //   // trackfields.composer.id,
  //   // trackfields.publisher.id,
  //   // trackfields.position.id,
  //   // trackfields.prodArtist.id,
  //   // trackfields.performer.id,
  //   // trackfields.alternativeTitle.id,
  //   // trackfields.duration.id,
  //   // trackfields.recordLabel.id,
  //   // trackfields.isrc.id,
  //   // trackfields.iswc.id,
  //   // trackfields.prs.id,
  // ],
  default: trackAllFields
};

export const _settingSearchShowAllTagTypes = {
  id: "ss2",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: true,
};

export const _settingSearchAllTagTypes = {
  id: "ss21",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let tagtype = {};
    Object.keys(trackfields).map((key) => {
      if (trackfields[key].tag) tagtype[key] = trackfields[key];
    });
    return tagtype;
  },
  default: [
    trackfields.genres.id,
    trackfields.styles.id,
    trackfields.moods.id,
    trackfields.instruments.id,
    trackfields.bpm.id,
    trackfields.tempo.id,
    trackfields.keywords.id,
  ],
};

export const _settingSearchOrigin = {
  id: "ss3",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: trackOrigins,
  default: [
    trackOrigins.originall.id,
    trackOrigins.origincommercial.id,
    trackOrigins.origincommissioned.id,
    trackOrigins.originlibrary.id,
    trackOrigins.originlibraryMcps.id,
    trackOrigins.originlibraryNonMcps.id,
    trackOrigins.originlive.id,
  ],
};

export const _settingSearchFields = {
  id: "ss4",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let flds = {};
    Object.keys(trackfields).map((key) => {
      if (!trackfields[key].sys) flds[key] = trackfields[key];
    });
    return flds;
  },
  default: [trackfields.id.id, trackfields.trackTitle.id, trackfields.prodArtist.id, trackfields.performer.id],
};

export const _settingAllKeyFields = {
  id: "ss5",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let flds = {};
    Object.keys(trackfields).map((key) => {
      if (!trackfields[key].sys) flds[key] = trackfields[key];
    });
    return flds;
  },
  default: [trackfields.id.id, trackfields.trackTitle.id, trackfields.prodArtist.id, trackfields.performer.id],
};


export const _settingMusicSearch = {
  id: "settingMusicSearch",
  permitType: PT_Setting,
  type: T_Major,
  modules: [
    _settingSearchShowGlobalQ,
    // _settingSearchShowAllFields, removed search all fields
    _settingSearchShowAllTagTypes,
    _settingSearchAllFields,
    _settingSearchAllTagTypes,
    _settingSearchOrigin,
    _settingSearchFields,
    _settingAllKeyFields,
  ],
};
