import { textLengths } from "../../redux/Types";

export const ValidateRule = (ruleData) => {
    let errors = {}

    if (ruleData) {
        if (!ruleData.name) {
            errors.name = "Should not be empty";
        } else if (ruleData.name && ruleData.name.length > textLengths.TEXT) {
            errors.name = "Sorry, the name you entered is too long. Please try a shorter name";
        }

        
    }



    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}