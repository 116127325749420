import "./App.css";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import axios from "axios";
import { Provider } from "react-redux";
import store from "redux/Store";
import { loadLoggedinUser, cacheNewToken } from "./redux/actions/UserActions";
import AuthRoute from "util/AuthRoute";
import Downloader from "./modules/ml-downloader/downloader/Downloader";

const Signin = React.lazy(() => import("pages/signin/Signin"));
const TwoFA = React.lazy(() => import("pages/twofa/TwoFA"));

const Signup = React.lazy(() => import("pages/signup/Signup"));
const Contact = React.lazy(() => import("./pages/contact/Contact"));
const ResetPassword = React.lazy(() =>
  import("./pages/reset-password/ResetPassword")
);

const HandleResetPassword = React.lazy(() =>
  import("./pages/reset-password/HandleResetPassword")
);
const Home = React.lazy(() => import("pages/home/Home"));

export const host_basename = process.env.REACT_APP_HOST_BASENAME;
axios.defaults.baseURL = process.env.REACT_APP_CENTRAL_API;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  function (successRes) {
    return successRes;
  },
  function (error) {
    if (error?.response?.status === 401) {
      window.location.replace(`${host_basename}/signin`);
      throw error;
    } else {
      throw error;
    }
  }
);

export const paging_type = process.env.REACT_APP_PAGING_TYPE;

store.dispatch(loadLoggedinUser());
const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

const styles = (theme) => ({
  appContainer: {},
});

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter basename={host_basename}>
            <div className={classes.appContainer} role="main">
              <Switch>
                <Route path="/signin" component={Signin} />
                <Route path="/2fa" component={TwoFA} />
                <Route
                  path="/resetpassword/:handle"
                  component={HandleResetPassword}
                />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route path="/contact" component={Contact} />
                <Route path="/signup/:handle" component={Signup} />
                <Route path="/bbcsso/:handle" component={Home} />
                <Route path="/downloader/:handle" component={Downloader} />
                <AuthRoute path="/" component={Home} />
              </Switch>
            </div>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
