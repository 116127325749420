import axios from "axios";
import store from "redux/Store";
import { __trackStatus } from "../../_clearance/definition/status";
import { __compileRules } from "../../_clearance/utils/utils";
module = "/clearanceRuntime";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  CTAG: "CTAG" + module,
  CTAG_ERROR: "CTAG_ERROR" + module,
  RESET_CTAG: "RESET_CTAG" + module,
  SET_COPY_LIVE_IDS: "SET_COPY_LIVE_IDS" + module,
  REMOVE_COPY_LIVE_IDS: "REMOVE_COPY_LIVE_IDS" + module,
};

export const loadRuntime = (ruleCollection) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.LOADING,
  });

  let _r = {
    _rules: null,
    _statuses: [],
    _params: [],
  };

  axios
    .get(`${orgID}/licensingruntime/${ruleCollection ? "nr" : "all"}`)
    .then((res) => {
      _r._rules = ruleCollection
        ? ruleCollection
        : __compileRules(res.data._rules, res.data._statuses);

      _r._statuses = [
        ...res.data._statuses,
        __trackStatus.norule,
        __trackStatus.inprogress,
      ];

      _r._params = [...res.data._params];

      if (_r._rules?.length && _r._params?.length && _r._statuses?.length) {
        dispatch({
          type: actionTypes.LOAD,
          payload: _r,
        });
      } else {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: "runtimeError" },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const resetCatg = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_CTAG,
  });
};

export const setCopyLiveIds = (copyLiveIds) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_COPY_LIVE_IDS,
    payload: copyLiveIds,
  });
};

export const removeCopyLiveIds = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_COPY_LIVE_IDS,
    payload: id,
  });
};
